import { useEffect, useState, useMemo } from 'react';
import Overlay from 'ol/Overlay';
import OLVectorLayer from 'ol/layer/Vector';
import {
  Style,
  Fill,
  Stroke,
  Text,
} from 'ol/style';
import { unByKey } from 'ol/Observable';
import FillPattern from 'ol-ext/style/FillPattern';
import * as d3 from 'd3';
import * as d3ColorScale from 'd3-scale-chromatic';
import { numberFormatter } from '../Helpers';
import { rgba } from '../../MapVizzard/MapOptions/ColorPicker';
import down from '../../MapVizzard/assets/down.svg';
import up from '../../MapVizzard/assets/up.svg';

function VectorLayer({
  map,
  layerId,
  source,
  style,
  zIndex = 1,
  opacity = 1,
  showLabels = false,
  labelColumn = '',
  enableTooltips = false,
  tooltipsTitleColumn = '',
  tooltipsValueColumn = '',
  tooltipsValueLabel = '',
  tooltipsValueColumnSecondary = '',
  tooltipsValueLabelSecondary = '',
  hoverFn = null,
  primaryColor,
  ts,
}) {
  const [vectorLayer, setVectorLayer] = useState(undefined);
  const [labelLayer, setLabelLayer] = useState(undefined);
  const [tooltipListener, setTooltipListener] = useState(undefined);
  const [hoverListener, setHoverListener] = useState(undefined);

  const colorsArray = useMemo(() => {
    if (!style.fillPalette) return [];
    let colors;
    if ((style.fillType === 'graduated') || (style.fillType === 'continuous')) {
      const interpolator = d3ColorScale[`interpolate${style.fillPalette}`];
      let numSteps = style.fillSteps;
      if (style.fillScaleType === 'continuous') numSteps = 50;
      // eslint-disable-next-line
      colors = Array.from({ length: numSteps }, (_, i) => interpolator(i * (1 / numSteps)));
    } else if (style.fillType === 'categorical') {
      colors = {};
      let i = 0;
      let palette = d3ColorScale[`scheme${style.fillPaletteCategorised}`];
      if ((style.fillScaleInvert) && (typeof palette !== 'undefined')) {
        palette = [...palette].reverse();
      }
      source.forEachFeature((d) => {
        // eslint-disable-next-line
        const index = d['values_'][style.fillColumn];
        if (typeof palette !== 'undefined') {
          colors[index] = palette[i];
          i += 1;
          if (i === palette.length) i = 0;
        }
      });
    } else {
      colors = d3ColorScale[`scheme${style.fillPalette}`];
    }
    if ((style.fillScaleInvert) && (style.fillType !== 'categorical')) colors.reverse();
    colors.forEach(function(d,i){
      var color = colors[i];
      if (!color.includes('rgb')) return color;
      var new_col = color.replace(/rgb/i, "rgba");
      new_col = new_col.replace(/\)/i,',0.75)');
      colors[i] = new_col;
    })
    return colors;
  }, [
    source,
    style.fillPalette,
    style.fillPaletteCategorised,
    style.fillScaleInvert,
    style.fillType,
    style.fillSteps,
    style.fillScaleType,
    style.fillColumn,
  ]);

  // polygon vectors
  useEffect(() => {
    if (!map) return undefined;
    const styles = [];
    let polygonStyle;
    let numSteps = style.fillSteps;
    let { fillPow } = style;
    if (style.fillScaleType === 'continuous') numSteps = 50;
    if (style.fillScaleType === 'steps') fillPow = 1;

    let fill = new Fill({
      color: rgba(style.fill),
    });

    const stroke = new Stroke({
      width: style.strokeWidth,
      color: rgba(style.stroke),
    });

    if (style.fillType === null) {
      fill = null;
    }

    if (style.fillSingleType === 'pattern') {
      fill = new FillPattern({
        pattern: 'hatch',
        color: rgba(style.fill),
        size: style.fillPatternSize,
        spacing: style.fillPatternSpacing,
        angle: style.fillPatternAngle,
      });
    }

    if (style.fillType === 'patternCustom') {
      fill = new FillPattern({
        pattern: 'hatch',
        color: 'red',
        size: 2,
        spacing: 10,
        angle: 45,
      });
    }

    if (style) {
      styles.push(
        (polygonStyle = new Style({ stroke, fill })),
      );
    }

    const colorsArrayPow = d3.scalePow()
      .exponent(fillPow)
      .domain([0, numSteps]);

    const colorScale = d3
      .scaleLinear()
      .range([0, numSteps])
      .domain([style.fillDataMin, style.fillDataMax]);

    let colorStrPow = null;
    if (style.fillType === 'categorical') {
      colorStrPow = null;
    } else {
      colorStrPow = colorsArray.map((c, i) => {
        const colorIndex = Math.round(colorsArrayPow(i) * numSteps);
        return (colorsArray[colorIndex]);
      });
    }

    const vLayer = new OLVectorLayer({
      source,
      style(feature) {
        // FILL LOGIC
        if (style.fillType === 'graduated') {
          let colorArrayIndex = Math.floor(colorScale(feature.get(style.fillColumn))) - 1;
          if (style.fillScaleType === 'steps') colorArrayIndex += 1;
          const polygonColor = colorStrPow[colorArrayIndex];
          if (typeof polygonColor !== 'undefined') {
            polygonStyle.getFill().setColor(polygonColor);
          } else {
            polygonStyle.getFill().setColor('transparent');
          }
        }
        if (style.fillType === 'categorical') {
          const polygonColor = colorsArray[feature.get(style.fillColumn)];
          if (typeof polygonColor !== 'undefined') {
            polygonStyle.getFill().setColor(polygonColor);
          } else {
            polygonStyle.getFill().setColor('transparent');
          }
        }
        if (style.fillType === 'patternCustom') {
          let val = feature.get(style.fillColumn);
          let colors = [];
          let len = 0;
          
          if ((val) && (val.includes('risk'))) {
            colors.push('rgba(185, 206, 228,0.75)');
            len ++;
          }
          if ((val) && (val.includes('hronic'))) {
            colors.push('rgba(41, 95, 160,0.75)');
            len ++;
          }
          if ((val) && (val.includes('cute'))) {
            colors.push('rgba(93, 5, 91,0.75)');
            len ++;
          }

          if ((len === 0) && (colors.length === 0)) {
            fill = new Fill({
              color: 'rgba(255,255,255,0.5)',
            });
          }

          if ((len === 1) && (colors.length === 1)) {
            fill = new Fill({
              color: colors[0],
            });
          }

          if ((len === 2) && (colors.length === 2)) {
            fill = new FillPattern({
              pattern: 'hatch',
              color: colors[0],
              fill: new Fill({
                color: colors[1],
              }),
              size: 3.5,
              spacing: 7,
              angle: 42,
            });
          }

          // const polygonColor = colorsArray[feature.get(style.fillColumn)];
          // if (typeof polygonColor !== 'undefined') {
          //   polygonStyle.getFill().setColor(polygonColor);
          // } else {
          //   polygonStyle.getFill().setColor('transparent');
          // }
          polygonStyle.setFill(fill);

        }
        return styles;
      },
    });

    map.addLayer(vLayer);
    vLayer.setZIndex(zIndex);
    vLayer.setOpacity(opacity);
    setVectorLayer(vLayer);

    // tooltip popups
    let popupOverlay;
    // const selected = null;

    if (hoverFn) {
      if (hoverListener) {
        unByKey(hoverListener);
      }
      const hv = map.on('pointermove', (event) => {
        if (event.dragging) {
          return;
        }

        const pixel = map.getEventPixel(event.originalEvent);
        vLayer.getFeatures(pixel).then((vectorFeatures) => {
          const feature = vectorFeatures.length ? vectorFeatures[0] : undefined;
          // let str = '';
          if (vectorFeatures.length) {
            hoverFn(feature);
            return (feature);
          }
        });
      });
      setHoverListener(hv);
    }
    if ((enableTooltips) && (map) && (layerId)) {
      const tt = document.createElement('div');
      tt.classList.add('tooltip');
      tt.classList.add(`tooltip_${layerId}`);
      tt.style.pointerEvents = 'none';
      document.body.appendChild(tt);

      popupOverlay = new Overlay({
        element: tt,
        offset: [12, -10],
      });
      map.addOverlay(popupOverlay);
      let thisId = null;
      let id = null;

      if (tooltipListener) {
        unByKey(tooltipListener);
      }

      const pv = map.on('pointermove', (event) => {
        if (event.dragging) {
          return;
        }

        const pixel = map.getEventPixel(event.originalEvent);
        vLayer.getFeatures(pixel).then((vectorFeatures) => {
          const feature = vectorFeatures.length ? vectorFeatures[0] : undefined;
          let str = '';
          if (vectorFeatures.length) {
            id = feature.ol_uid;
            if ((id !== thisId) || (!thisId)) {
              if (tooltipsTitleColumn) {
                str += `${feature.get(tooltipsTitleColumn)}<br/>`;
              }
              if ((tooltipsValueColumn) && (tooltipsValueColumn.length > 3)) {
                str += `<b style="color: ${(primaryColor)}"> ${numberFormatter(feature.get(tooltipsValueColumn))}</b><div style="display: inline; font-size: 9px; padding-left: 3px;">${tooltipsValueLabel}</div>`;
              }
              if (tooltipsValueColumnSecondary) {
                if ((tooltipsValueColumn) && (tooltipsValueColumn.length > 3)) str += '<br />';
                str += `<div style="margin-top: -2px"><b style="color: ${rgba(style.stroke)}"> ${numberFormatter(feature.get(tooltipsValueColumnSecondary))}</b><div style="display: inline; font-size: 9px; padding-left: 3px;">${tooltipsValueLabelSecondary}</div></div>`;
              }
              if (style.fillPaletteNegative) {
                if ((tooltipsValueColumn) && (tooltipsValueColumn.length > 3)) str += '<br/>';
                let val = feature.get(style.fillColumn);
                if (style.tooltipColumn) val = feature.get(style.tooltipColumn);
                if (val > 0) {
                  str += `<img height="10px" src=${up} />`;
                  str += `<span style="font-weight: bold; color: darkred">${numberFormatter(Math.abs(val))}</span>`;
                } else if (val < 0) {
                  str += `<img height="10px" src=${down} />`;
                  str += `<span style="font-weight: bold; color: steelblue">${numberFormatter(Math.abs(val))}</span>`;
                }
              }
              // if (tooltipsStyle === 'Population/Percentage') {
              //   str += '<br/>';
              //   str += 'pop/percent';
              // }
              tt.innerHTML = str;
              if (str.length > 0) tt.removeAttribute('hidden');
              // selected = feature;
              // if (selected) selected.setStyle(styleFunction(selected));
              // feature.setStyle(styleFunction(feature, true));
            }
            popupOverlay.setPosition(event.coordinate);
            thisId = id;
          } else {
            tt.setAttribute('hidden', true);
            // if (selected) selected.setStyle(styleFunction(selected));
            thisId = null;
            id = 0;
            tt.innerHTML = '&nbsp;';
          }
        });
      });

      setTooltipListener(pv);
    }

    return () => {
      if (map) {
        map.removeLayer(vLayer);

        if ((typeof popupOverlay !== 'undefined')) {
          map.removeOverlay(popupOverlay);
        }

        if (enableTooltips) {
          const tooltips = document.querySelectorAll(`.tooltip_${layerId}`);
          tooltips.forEach((t) => {
            t.remove();
          });
        }
        if (tooltipListener) {
          unByKey(tooltipListener);
        }

        if (hoverListener) {
          unByKey(hoverListener);
        }
      }
    };
  }, [
    map,
    JSON.stringify(style),
    // enableTooltips,
    // tooltipsTitleColumn,
    // tooltipsValueColumn,
    // hoverFn,
    // primaryColor,
    // tooltipsValueLabel
  ]);

  // text labels
  useEffect(() => {
    if (!map) return undefined;
    const labelStyles = [];
    let labelStyle;

    if (showLabels && labelColumn) {
      // with text labels
      let stroke = new Stroke({
        color: 'rgba(255,255,255,0.5)',
        width: 2,
      });
      if (style.labelStyle.showHalo === false) stroke = null;
      labelStyle = new Style({
        text: new Text({
          font: `${style.labelStyle.fontWeight} ${style.labelStyle.fontSize}px/1.07 ${style.labelStyle.fontFamily},sans-serif`,
          overflow: true,
          fill: new Fill({
            color: rgba(style.labelStyle.color),
          }),
          stroke,
        }),
      });
      labelStyles.push(labelStyle);
    }

    const textLayer = new OLVectorLayer({
      source,
      renderMode: 'vector',
      style(feature) {
        if (showLabels && labelColumn) {
          let columnName = labelColumn;
          if (!feature.get(columnName)) columnName = labelColumn;
          let label = String(feature.get(columnName)).split(' ').join('\n');
          if (style.labelStyle.transform === 'uppercase') label = label.toUpperCase();
          labelStyle.getText().setText(label);
        }
        return labelStyles;
      },
      declutter: true,
    });

    map.addLayer(textLayer);
    textLayer.setZIndex(zIndex);
    textLayer.setOpacity(opacity);
    setLabelLayer(textLayer);

    return () => {
      if (map) {
        map.removeLayer(textLayer);
      }
    };
  }, [map, showLabels, labelColumn, JSON.stringify(style)]);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setSource(source);
  }, [vectorLayer, source]);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setOpacity(opacity);
  }, [vectorLayer, opacity]);

  useEffect(() => {
    if (!labelLayer) return;
    labelLayer.setOpacity(opacity);
  }, [labelLayer, opacity]);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setZIndex(zIndex);
  }, [vectorLayer, zIndex]);

  useEffect(() => {
    if (!labelLayer) return;
    labelLayer.setZIndex(zIndex);
  }, [labelLayer, zIndex]);

  return null;
}

export default VectorLayer;
