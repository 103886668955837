import { useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { createTheme } from '@mui/material/styles';
import ReactHighlightSyntax from 'react-highlight-syntax';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import styles from './SudanDashboard/style/SudanDashboardIframe.module.css';

function SudanDashboardIframe() {
  const ref = useRef(null);
  const [resizeMode, setResizeMode] = useState('fixedHeight');
  const [aspectRatioX, setAspectRatioX] = useState(4);
  const [aspectRatioY, setAspectRatioY] = useState(3.3);
  const [scale, setScale] = useState(0.75);
  const [minHeight, setMinHeight] = useState(700);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#be2126',
        dark: '#be2126',
        light: '#be2126',
        contrastText: '#be2126',
      },
      secondary: {
        main: 'rgb(65, 143, 222)',
        dark: 'rgb(65, 143, 222)',
        light: 'rgb(65, 143, 222)',
        contrastText: 'rgb(65, 143, 222)',
      },
    },
  });

  let rootContainerConfig = {
    boxShadow: '2px 2px 10px 5px rgba(10,10,10,0.04)',
    marginTop: '-18px',
  };

  let containerConfig = {
    width: 'auto',
    margin: '30px auto',
    overflow: 'hidden',
    aspectRatio: `${aspectRatioX}/${aspectRatioY}`,
  };

  let iframeConfig = {
    width: `calc((100%)*${1 / scale})`,
    aspectRatio: `${aspectRatioX}/${aspectRatioY}`,
    transform: `scale(${scale})`,
    transformOrigin: '0 0',
  };

  if (resizeMode === 'fixedHeight') {
    containerConfig = {
      width: 'auto',
      margin: '30px auto',
      overflow: 'hidden',
      height: minHeight,
    };

    iframeConfig = {
      width: `calc((100%)*${1 / scale})`,
      height: minHeight * (1 / scale),
      transform: `scale(${scale})`,
      transformOrigin: '0 0',
    };
  }

  if (resizeMode === 'fullSize') {
    containerConfig = {
      width: '100%',
      margin: '30px auto',
      overflow: 'hidden',
      height: '100%',
    };

    iframeConfig = {
      width: `calc((100%)*${1 / scale})`,
      height: `calc((100%)*${1 / scale})`,
      transform: `scale(${scale})`,
      transformOrigin: '0 0',
    };

    rootContainerConfig = {
      height: '700px',
      boxShadow: '2px 2px 10px 5px rgba(10,10,10,0.04)',
      marginBottom: 40,
      marginTop: '-18px',
    };
  }

  const preview = (
    <div style={containerConfig}>
      <iframe frameBorder="0" style={iframeConfig} title="iframe" src="https://sudan2023.thedeep.io/#/" />
    </div>
  );

  return (
    <div style={{
      margin: '0 auto', maxWidth: 1000, position: 'relative', fontFamily: 'Barlow Condensed',
    }}
    >
      <h3>iFrame embed</h3>
      <div className={styles.config}>
        <div className={styles.configTitle}>Configuration</div>
        <div className={styles.configBody}>
          <div className={styles.control}>
            <div className={styles.resizeModeTitle}>Resize Mode</div>
            <ToggleButtonGroup
              value={resizeMode}
              color="primary"
              exclusive
              // orientation="vertical"
              theme={theme}
              size="small"
              onChange={(event, newValue) => {
                if (newValue) {
                  setResizeMode(newValue);
                }
              }}
              aria-label="Filter"
            >
              <ToggleButton theme={theme} value="fixedHeight" style={{ justifyContent: 'flex-start' }}>
                Fixed Height
              </ToggleButton>
              <ToggleButton theme={theme} value="aspectRatio" style={{ justifyContent: 'flex-start' }}>
                {/* <img width="15px" src={hexbinIcon} alt="" /> */}
                Aspect Ratio
              </ToggleButton>
              <ToggleButton theme={theme} value="fullSize" style={{ justifyContent: 'flex-start' }}>
                {/* <img width="15px" src={adm1Icon} alt="" /> */}
                Fit Full Size
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={styles.control}>
            <FormControl fullWidth>
              <TextField
                label="Zoom Scale"
                variant="standard"
                value={scale}
                type="number"
                size="small"
                inputProps={{
                  step: 0.01,
                }}
                onChange={(e) => setScale(parseFloat(e.target.value, 10))}
              />
            </FormControl>
          </div>
          {resizeMode === 'aspectRatio' && (
            <div className={styles.control}>
              <FormControl fullWidth>
                <TextField
                  label="Aspect ratio (width)"
                  variant="standard"
                  value={aspectRatioX}
                  type="number"
                  size="small"
                  inputProps={{
                    step: 0.1,
                  }}
                  onChange={(e) => setAspectRatioX(parseFloat(e.target.value, 10))}
                />
              </FormControl>
            </div>
          )}
          {resizeMode === 'aspectRatio' && (
            <div className={styles.control}>
              <FormControl fullWidth>
                <TextField
                  label="Aspect ratio (height)"
                  variant="standard"
                  value={aspectRatioY}
                  type="number"
                  size="small"
                  inputProps={{
                    step: 0.1,
                  }}
                  onChange={(e) => setAspectRatioY(parseFloat(e.target.value, 10))}
                />
              </FormControl>
            </div>
          )}
          {resizeMode === 'fixedHeight' && (
            <div className={styles.control}>
              <FormControl fullWidth>
                <TextField
                  label="Height"
                  variant="standard"
                  value={minHeight}
                  type="number"
                  size="small"
                  inputProps={{
                    step: 1,
                  }}
                  onChange={(e) => setMinHeight(parseFloat(e.target.value, 10))}
                />
              </FormControl>
            </div>
          )}
        </div>
        {resizeMode === 'fixedHeight' && (
          <div className={styles.description}>Fixed height with a dynamic fluid width</div>
        )}
        {resizeMode === 'aspectRatio' && (
          <div className={styles.description}>
            Dynamic resizing according to a fixed uniform aspect-ratio
          </div>
        )}
        {resizeMode === 'fullSize' && (
          <div className={styles.description}>
            Fill to fit the full-size of the parent container
          </div>
        )}
      </div>
      <div className={styles.code}>
        <div className={styles.codeTitle}>Embed code</div>
        <div className={styles.codeBody}>
          <ReactHighlightSyntax
            language="HTML"
            theme="GithubDark"
            copy
            copyBtnTheme="Dark"
          >
            {ReactDOMServer.renderToStaticMarkup(preview)}
          </ReactHighlightSyntax>
        </div>
      </div>
      <div className={styles.previewTitle}>iFrame Preview</div>
      <div ref={ref} className={styles.container} style={rootContainerConfig}>
        {preview}
      </div>
    </div>
  );
}

export default SudanDashboardIframe;
