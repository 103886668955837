import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { numberFormatter } from '../components/Map/Helpers';
import { Tooltip } from 'react-tooltip';
import { KPIs } from '@the-deep/reporting-module-components';
import '@the-deep/reporting-module-components/build/esm/index.css';
import * as d3 from 'd3';
import MapVizzard from '../components/MapVizzard';
import styles from './SudanDashboard/style/SudanDashboard.module.css';
import SudanD3Map from './SudanD3Map';

function SudanMapGrid({
  config, kpiData, size, subView, adm1Filter, adm2Filter, gridSort, numCols = 'auto', tabIndex = 0,
}) {

  const ipcColorArray = ['rgba(105, 189, 169, 0.8)', 'rgba(224, 243, 160, 0.8)', 'rgba(254, 221, 141, 0.8)', 'rgba(240, 112, 74, 0.8)', 'rgba(158, 1, 66, 0.8)']

  const [data, setData] = useState(config);
  const [hoverAdm2, setHoverAdm2] = useState(null);
  const [adm2WebGlFeatures, setAdm2WebGlFeatures] = useState([]);
  const [colWidth, setColWidth] = useState('50vw');
  const [columns, setColumns] = useState([]);

  const cols = useMemo(() => {
    let colW;
    if (numCols === 'auto') {
      colW = 'calc(50% - 8px)';
      if (size.width > 1500) colW = 'calc(33.3% - 7px)';
      if (size.width < 900) colW = 'calc(100% - 5px)';
    } else {
      if (numCols == 1) colW = 'calc(100% - 5px)';
      if (numCols == 2) colW = 'calc(50% - 8px)';
      if (numCols == 3) colW = 'calc(33vw)';
      if (numCols == 4) colW = 'calc(25% - 8px)';
    }
    setColWidth(colW);
  }, [numCols, size]);

  const headerMap = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(data));
    copyData.layers = [];
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    copyData.mapOptions.height = 56;
    copyData.mapOptions.showLegend = false;
    copyData.mapOptions.enableZoomControls = false;
    return <MapVizzard key="header" mapConfig={copyData} iframe />;
  }, [data]);

  const dataFiltered = useMemo(() => {
    const copyData = JSON.parse(JSON.stringify(data));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    let { features } = layer.data;
    if (subView === 'khartoum') features = features.filter((d) => d.properties.ADM1_PCODE === 'SD01');
    if (subView === 'darfur') features = features.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));
    if (subView === 'kordofan') features = features.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
    // eslint-disable-next-line max-len
    if (adm1Filter.length > 0) features = features.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE));
    if (adm2Filter.length > 0) features = features.filter((d) => adm2Filter.includes(d.properties.ADM2_PCODE));
    layer.data.features = features;
    layer.showInLegend = true;
    layer.enableTooltips = false;
    layer.zIndex = 1000;
    layer.style.fillType = 'graduated';
    layer.style.fillScaleType = 'continuous';
    layer.class = 'gridAdm2x';
    layer.style.ts = Date.now().toString();
    const layerAdm1Index = copyData.layers.findIndex((o) => o.name === 'SDN_ADM1.geojson');
    const layerAdm1 = copyData.layers[layerAdm1Index];
    let adm1Features = layerAdm1.data.features;
    if (subView === 'khartoum') adm1Features = adm1Features.filter((d) => d.properties.ADM1_PCODE === 'SD01');
    if (subView === 'darfur') adm1Features = adm1Features.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));
    if (subView === 'kordofan') adm1Features = adm1Features.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
    // eslint-disable-next-line max-len
    if (adm1Filter.length > 0) adm1Features = adm1Features.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE));
    layerAdm1.data.features = adm1Features;
    layerAdm1.class = 'gridAdm1';
    layerAdm1.zIndex = 1001;
    layerAdm1.style.strokeWidth = 0.5;
    // duplicate layer for select
    const layerSelect = JSON.parse(JSON.stringify(layer));
    layerSelect.id = layerSelect.id+400;
    layerSelect.zIndex = 1002;
    layerSelect.style.fillType = 'single';
    layerSelect.class = 'gridAdm2';
    copyData.layers.push(layerSelect);
    layer.tooltipsTitleColumn = 'ADM2_EN';
    const disputedBoundaries1Index = copyData.layers.findIndex((o) => o.name === 'Disputed boundaries');
    const disputedBoundaries1 = copyData.layers[disputedBoundaries1Index];
    disputedBoundaries1.zIndex = 2000;
    const disputedBoundaries2Index = copyData.layers.findIndex((o) => o.name === 'Disputed boundaries (2)');
    const disputedBoundaries2 = copyData.layers[disputedBoundaries2Index];
    disputedBoundaries2.zIndex = 2000;
    return copyData;
  }, [JSON.stringify(data), adm1Filter, adm2Filter, columns]);

  const mapEvents = useMemo(() => {
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'acled_events';
    layer.style.fillPalette = 'Reds';
    layer.legendSeriesTitle = 'ACLED Events';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.acled_events);
    layer.tooltipsValueColumn = 'acled_events';
    layer.tooltipsValueLabel = 'Events';
    layer.primaryColor = '#be2126';

    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );

  }, [JSON.stringify(dataFiltered)]);

  const mapFatalities = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'acled_fatalities';
    layer.legendSeriesTitle = 'ACLED Fatalities';
    layer.style.fillPalette = 'Reds';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.acled_fatalities);
    layer.tooltipsValueColumn = 'acled_fatalities';
    layer.tooltipsValueLabel = 'Fatalities';
    layer.primaryColor = '#be2126';

    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  const mapIDPs = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'idp_n';
    layer.legendSeriesTitle = 'Total IDPs';
    layer.style.fillPalette = 'Blues';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.idp_n);
    layer.tooltipsValueColumn = 'idp_n';
    layer.tooltipsValueLabel = 'IDPs';
    layer.primaryColor = 'rgb(82 144 206)';

    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  const mapIDPIncreases = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'idp_increases';
    layer.legendSeriesTitle = 'IDPs increases';
    layer.style.fillPalette = 'Blues';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.idp_increases);
    layer.primaryColor = 'rgb(82 144 206)';
    layer.tooltipsValueColumn = '';
    layer.tooltipsValueLabel = '';
    layer.style.tooltipColumn = 'idp_increases';

    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  const mapIDPDecreases = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'idp_decreases';
    layer.legendSeriesTitle = 'IDPs decreases';
    layer.style.fillPalette = 'Blues';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.idp_decreases);
    layer.primaryColor = '#be2126';
    layer.tooltipsValueColumn = '';
    layer.tooltipsValueLabel = '';
    layer.style.tooltipColumn = 'idp_decreases';

    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  const mapP3Plus = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'p3plus';
    layer.legendSeriesTitle = 'Population in IPC Phase 3+';
    layer.style.fillPalette = 'OrRd';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.p3plus);
    layer.tooltipsValueColumn = 'p3plus';
    layer.tooltipsValueLabel = 'Population in IPC Phase 3+';
    layer.primaryColor = 'rgb(229 106 84)';
    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  const mapIPC = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillType = 'IPC';
    layer.style.fillScaleType = 'continuous';
    layer.style.fillColumn = 'overall_phase';
    layer.legendSeriesTitle = 'IPC Phase';
    layer.style.fillPalette = 'IPC';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = 5;
    layer.tooltipsValueColumn = 'overall_phase';
    layer.tooltipsValueLabel = 'IPC Phase';
    layer.primaryColor = 'rgb(229 106 84)';
    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  const mapPopulation = useMemo(() => {
    if (!data) return false;
    const copyData = JSON.parse(JSON.stringify(dataFiltered));
    const layerIndex = copyData.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = copyData.layers[layerIndex];
    layer.style.fillColumn = 'estimated_population';
    layer.legendSeriesTitle = 'Estimated Population';
    layer.style.fillPalette = 'Greys';
    layer.style.fillScaleInvert = false;
    layer.style.fillDataMax = d3.max(layer.data.features, (d) => d.properties.estimated_population);
    layer.tooltipsValueColumn = 'estimated_population';
    layer.tooltipsValueLabel = 'people';
    layer.primaryColor = 'grey';
    return (
      <SudanD3Map 
        height={700}
        width={900}
        center={[copyData.mapOptions.center.lon, copyData.mapOptions.center.lat]}
        zoom={copyData.mapOptions.zoom*3.7}
        style={layer.style}
        layers={copyData.layers}
        columns={columns}
      />
    );
  }, [JSON.stringify(dataFiltered)]);

  // eslint-disable-next-line max-len
  const [kpiData1, kpiData2, kpiData3, kpiData4, kpiData5, kpiData6, kpiData7, kpiData8] = useMemo(() => kpiData.kpis.map((kpi) => ({ kpis: [kpi] })), [kpiData]);

  const mapArrayInit = [{
    id: 'acled_events',
    label: 'Political Violence Events',
    color: '#be2126',
    obj: mapEvents,
    kpi: kpiData1
  },
  {
    id: 'acled_fatalities',
    label: 'Political Violence Fatalities',
    color: '#be2126',
    obj: mapFatalities,
    kpi: kpiData2,
  },
  {
    id: 'idp_n',
    label: 'IDPs',
    color: 'rgb(82 144 206)',
    obj: mapIDPs,
    kpi: kpiData3,
  },
  {
    id: 'idp_increases',
    label: 'IDP Increases',
    color: 'rgb(82 144 206)',
    obj: mapIDPIncreases,
    kpi: kpiData4,
  },
  {
    id: 'idp_decreases',
    label: 'IDP Decreases',
    color: 'rgb(82 144 206)',
    obj: mapIDPDecreases,
    kpi: kpiData5,
  },
  {
    id: 'p3plus',
    label: 'Population in Phase 3+',
    color: 'rgb(229 106 84)',
    obj: mapP3Plus,
    kpi: kpiData7,
  },{
    id: 'estimated_population',
    label: 'Total Population',
    color: 'grey',
    obj: mapPopulation,
    kpi: kpiData8,
  },
  {
    id: 'overall_phase',
    label: 'Food Insecurity Phase',
    color: 'rgb(229 106 84)',
    obj: mapIPC,
    kpi: kpiData6,
  }
];
const [mapArray, setMapArray] = useState(mapArrayInit);

   const maps = useMemo(() => mapArray.map((d) => {
    return (
      <div key={d.id} className={styles.mapGridContainer} style={{width: colWidth}}>
          <div className={styles.mapGridOuter}>
            <div className={styles.mapGridInner}>
              <div className={styles.mapGridKpi} style={{borderTop: '2px solid '+d.kpi.kpis[0].primaryColor}}>
                <KPIs data={d.kpi} />
              </div>
              <div className={styles.mapGridVizzard}>
                {d.obj}
              </div>
            </div>
          </div>
        </div>
    );
  }), [mapArray]);
  
  useEffect(() => {
    const newColumns = [];
    gridSort.forEach(function(d){
      mapArrayInit.forEach(function(dd){
        if(dd.id === d){
          newColumns.push({id: dd.id, color: dd.color, label: dd.label});
        }
      })
    })
    setColumns(newColumns);
  },[gridSort, tabIndex]);

  useEffect(() => {
    const newArray = [];
    gridSort.forEach(function(d){
      mapArrayInit.forEach(function(dd){
        if(dd.id === d){
          newArray.push({...dd});
        }
      })
    })
    setMapArray(newArray);
  },[columns]);

  useEffect(() => {
    const newArray = [];
    gridSort.forEach(function(d){
      mapArrayInit.forEach(function(dd){
        if(dd.id === d){
          newArray.push({...dd});
        }
      })
    })
    setMapArray(newArray);
  },[kpiData1]);
   
  return (
    <div className={styles.mapgrid}>
      <div>
          {headerMap}
      </div>
      <div className={styles.mapGridMain}>
      <Tooltip
                variant="light"
                className={styles.barTooltipDiv}
                anchorSelect=".gridAdm2"
                place="top-start"
                offset={7}
                opacity={1}
                noArrow
                style={{ zIndex: 1000 }}
                render={({ content, activeAnchor }) => {
                  if(!activeAnchor) return;
                  let activeAdm2 = activeAnchor.__data__.properties;
                  let parent = activeAnchor.parentNode.parentElement;
                  if(!parent) return;
                  let dataId = parent.getAttribute('data-id');
                  if ((activeAdm2) && (activeAdm2.ADM2_PCODE)) {
                    let rows = columns.map(column => {
                      let background = {};
                      if (column.id === dataId) background = {backgroundColor: column.color, color: '#FFF', borderRadius: 0}
                      let c = column.color;
                      if (column.id === 'overall_phase'){
                        c = ipcColorArray[activeAdm2[column.id]-1];
                        if (activeAdm2[column.id] <= 3) background.color = '#000';
                        if (activeAdm2[column.id] >= 4) background.color = '#FFF';
                        background.backgroundColor = c;
                      }
                      return (
                        <div key={column.label} className={styles.tooltipRow}>
                          <div className={styles.tooltipLabel}>{column.label}</div>
                          <div className={styles.tooltipValue} style={{color: c }}><div style={{padding: '0px 3px', display: 'inline-block', ...background}}>{activeAdm2[column.id] ? numberFormatter(activeAdm2[column.id]) : 0}</div></div>
                        </div>
                      )
                    });
                    return (
                      <div className={styles.tooltipDiv}>
                        <div className={styles.tooltipTitle}>{activeAdm2.ADM2_EN}</div>
                        <div className={styles.tooltipSubTitle}>{activeAdm2.ADM2_PCODE}</div>
                        <br/>
                        <div className={styles.tooltipAltTitle}>{activeAdm2.ADM1_EN}</div>
                        <div className={styles.tooltipAltSubTitle}>{activeAdm2.ADM1_PCODE}</div>
                        {rows}
                      </div>
                    )
                  }}
                }
              />
        {maps}
        
      </div>
    </div>
  );
}

export default SudanMapGrid;
