import { createTheme } from '@mui/material/styles';
import { Tabs as ArkTabs } from '@ark-ui/react';
import AppBar from '@mui/material/AppBar';
import {
  Link,
} from 'react-router-dom';
import styles from './SudanDashboard/style/SudanDashboard.module.css';

const SudanNav = ({
  id = "sudan-d3-map",
  onChange,
  tabIndex,
  subView,
  subViewArr,
}) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#be2126',
        dark: '#be2126',
        light: '#be2126',
        contrastText: '#be2126',
      },
      secondary: {
        main: 'rgb(65, 143, 222)',
        dark: 'rgb(65, 143, 222)',
        light: 'rgb(65, 143, 222)',
        contrastText: 'rgb(65, 143, 222)',
      },
    },
  });

  const themeDisp = createTheme({
    palette: {
      primary: {
        main: 'rgb(82 144 206)',
      },
    },
  });

  const themeProtection = createTheme({
    palette: {
      primary: {
        main: '#295fa0',
      },
    },
  });

  const themeFoodSecurity = createTheme({
    palette: {
      primary: {
        main: 'rgb(229 106 84)',
      },
    },
  });

  return (
    <div>
      <AppBar
        position="static"
        color="transparent"
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 9999,
          boxShadow: 'none',
        }}
      >
      <ArkTabs.Root
        value={tabIndex}
        onValueChange={({ value }) => {
          onChange(1, value);
          // navigate(`/${value === 'home' ? '' : value}`)
        }}
      >
        <ArkTabs.List>
          <ArkTabs.Trigger value={0} className={styles.conflictTab}><Link to={`/${subViewArr[subView]}`}>Conflict</Link></ArkTabs.Trigger>
          <ArkTabs.Trigger value={1} className={styles.displacementTab}><Link to={`displacement/${subViewArr[subView]}`}>Displacement</Link></ArkTabs.Trigger>
          <ArkTabs.Trigger value={2} className={styles.foodSecurityTab}><Link to={`food-security/${subViewArr[subView]}`}>Food Security</Link></ArkTabs.Trigger>
          <ArkTabs.Trigger value={3} className={styles.protectionTab}><Link to={`protection/${subViewArr[subView]}`}>Protection</Link></ArkTabs.Trigger>
          <ArkTabs.Trigger value={10}><Link to={`grid/${subViewArr[subView]}`}>Grid</Link></ArkTabs.Trigger>
          <ArkTabs.Trigger value={11}><Link to={`documents`}>Documents</Link></ArkTabs.Trigger>
        </ArkTabs.List>
      </ArkTabs.Root>
      </AppBar> 

    </div>
  )  
};

export default SudanNav;