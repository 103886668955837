
import moment from 'moment';
import Papa from 'papaparse';
import mapConfig from './SudanDashboard/data/SUDAN_mapConfig.json';
import dtmAdm2 from './SudanDashboard/data/dtm_adm2.json';
import dtmTimeseries from './SudanDashboard/data/dtm_timeseries.json';
import acled from './SudanDashboard/data/acled.json';
import refugeesImport from './SudanDashboard/data/refugees.json';
import foodSecurityData from './SudanDashboard/data/sdn_ipc_latest.json';

import protData from './SudanDashboard/data/prot_hotspot.json';
import protAggData from './SudanDashboard/data/prot_hotspot_agg.json';
// data sources not in the database
import occupiedSchoolsImport from './SudanDashboard/data/occupied_schools.csv';

mapConfig.layers.forEach((layer) => {
  if (layer.name === 'Refugees') {
    layer.data.forEach((feature, i) => {
      let f = feature;
      refugeesImport.forEach((ref) => {
        if (ref.adm0_iso3 === f.iso3) {
          f = { ...feature, ...ref };
          f.refugee_n = parseInt(ref['refugee_n']);
          f.date = moment(ref['date'], 'YYYY-MM-DD');
          // eslint-disable-next-line no-param-reassign
          layer.data[i] = f;
        }
      });
    });
  }
});

const occupiedSchools = await fetch(occupiedSchoolsImport).then( res => res.text() );

Papa.parse( occupiedSchools, {
    header: true,
    complete: function(results, file) {
        mapConfig.layers.forEach((layer) => {
          if (layer.name === 'SDN_ADM1_centroids') {
            layer.data.features.forEach((feature, i) => {
              let f = feature;
              results.data.forEach((row) => {
                if (row.adm1_pcode === f.properties.ADM1_PCODE) {
                  f.properties.occupied_schools = parseInt(row['occupied_schools']);
                  f.properties.occupied_schools_date = moment(row['date'], 'YYYY-MM-DD');
                  // eslint-disable-next-line no-param-reassign
                  layer.data[i] = f;
                }
              });
            });
          }
        });
    }
});

acled.forEach((row) => {
  // eslint-disable-next-line no-param-reassign
  row.lat = row.y;
  // eslint-disable-next-line no-param-reassign
  row.lon = row.x;
});

dtmTimeseries.forEach((d) => {
  const dt = moment(d.snapshot_date, 'YYYY-MM-DD');
  d.date = dt;
});

export const dtmTime = dtmTimeseries;

mapConfig.layers.forEach((layer) => {
  if ((layer.name === 'SDN_ADM2.geojson') || (layer.name === 'SDN_ADM2_centroids')) {
    layer.data.features.forEach((feature) => {
      const f = feature;
      foodSecurityData.forEach((row) => {
        const r = row;
        if (row.adm2_pcode === f.properties.ADM2_PCODE) {
          f.properties = { ...feature.properties, ...r };
          r.adm1_pcode = f.properties.ADM1_PCODE;
        }
      });
      dtmAdm2.forEach((geo) => {
        if (geo.adm2_pcode === f.properties.ADM2_PCODE) {
          f.properties = { ...feature.properties, ...geo };
          f.properties.idp_increases = 0;
          f.properties.idp_decreases = 0;
          if (f.properties.idp_diff > 0) f.properties.idp_increases = f.properties.idp_diff;
          if (f.properties.idp_diff < 0) f.properties.idp_decreases = Math.abs(f.properties.idp_diff);
        }
      });

      f.properties.prot_hotspot = [];

      protData.forEach((row) => {
        const r = row;
        if (row.adm2_pcode === f.properties.ADM2_PCODE) {
          r.adm1_pcode = f.properties.ADM1_PCODE;
          r.adm1_en = f.properties.ADM1_EN;
          r.adm2_en = f.properties.ADM2_EN;
          f.properties.prot_hotspot.push(r);
        }
      });

      f.properties.prot_hotspot_count = f.properties.prot_hotspot.length;

      let protDate;

      protAggData.forEach((row) => {
        const r = row;
        if (row.adm2_pcode === f.properties.ADM2_PCODE) {
          f.properties.prot_hotspot_category = r.hotspot_category1;
        }
      });

      f.properties.prot_hotspot_data_date = protAggData[0].data_date.slice(0,10);

    });
  }
  if ((layer.name === 'SDN_ADM1.geojson') || (layer.name === 'SDN_ADM1_centroids')) {

  }

  if (layer.name === 'ACLED EVENTS') {
    // eslint-disable-next-line no-param-reassign
    layer.data = acled;
    layer.data.forEach((d) => {
      const dt = moment(d.event_date, 'YYYY-MM-DD');
      // eslint-disable-next-line no-param-reassign
      d.event_date_ts = dt.unix();
      // eslint-disable-next-line no-param-reassign
      d.event_date_month = dt.format('YYYY-MM');
    });
  }

});
export const parsedData = {
  ...mapConfig
};


export default parsedData;