import { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { numberFormatter } from '../components/Map/Helpers';
import ColorScale from '../components/MapVizzard/MapOptions/ColorScale';
import { rgba } from '../components/MapVizzard/MapOptions/ColorPicker';
import styles from './SudanDashboard/style/SudanDashboard.module.css';
import downIcon from './SudanDashboard/images/down.svg';

const ipcColorArray = ['rgba(105, 189, 169, 0.8)', 'rgba(224, 243, 160, 0.8)', 'rgba(254, 221, 141, 0.8)', 'rgba(240, 112, 74, 0.8)', 'rgba(158, 1, 66, 0.8)']

const SudanD3Map = ({
  id = "sudan-d3-map",
  dataId = "sudan-d3-map",
  width = 800,
  height = 600,
  center = [0, 0],
  zoom = 1,
  style = {},
  layers,
  columns,
  projectionType = "geoMercator"
}) => {
  const ref = useRef();
  const markerRef = useRef();
  const legendArr = [];
  let activeAdm2 = null;
  
  useEffect(() => {
    if (!layers) return;
    const svg = d3.select(ref.current);
    // Choose projection type
    const projection = d3[projectionType]()
      .center(center) // Centering the map
      .scale(zoom*120) // Adjusting the zoom level
      .translate([width / 2, height / 2]);

    const pathGenerator = d3.geoPath().projection(projection);

    // Sort layers by zIndex
    layers.sort((a, b) => a.zIndex - b.zIndex);

    // draw layers
    layers.forEach(layer => {
      if (style.fillPalette !== 'IPC') layer.colorScale = d3.scaleSequential(d3[`interpolate${style.fillPalette}`]).domain([layer.style.fillDataMin, layer.style.fillDataMax]);
      else if (style.fillPalette === 'IPC') {
        layer.colorScale = function(d){
          if (d === 0) return 'rgba(255,255,255,0.2)';
          if (d === 1) return 'rgba(105, 189, 169, 0.6)';
          if (d === 2) return 'rgba(224, 243, 160, 0.6)';
          if (d === 3) return 'rgba(254, 221, 141, 0.6)';
          if (d === 4) return 'rgba(240, 112, 74, 0.6)';
          if (d === 5) return 'rgba(158, 1, 66, 0.6)';
        }
      }
      if (layer.type === 'polygon'){
        const g = svg.append('g').attr('class', 'layer polygonLayer');
        // Bind data to paths
        const paths = g.selectAll(".layer"+layer.name)
        .data(layer.data.features, feature => feature.properties[layer.labelColumn]); // Use a key function for object constancy
        

        let className = "layer"+layer.name;
        if (layer.class) className += " " + layer.class;

        // Enter selection: Create new path elements for new data
        var poly = paths.enter()
          .append("path")
          .attr("class", function(d) {
            let clss = className;
            if (layer.class === 'gridAdm2') clss += " gridAdm2" + d.properties.ADM2_PCODE;
            if (layer.class === 'gridAdm2x') clss += " gridAdm2" + d.properties.ADM2_PCODE;
            if (layer.class === 'gridAdm1') clss += " gridAdm1" + d.properties.ADM1_PCODE;
            return clss;
          })
          .attr("data-val", function(d){
            const val = d.properties[layer.style.fillColumn];
            return val || 0;
          })
          .attr("data-max", function(d){
            const val = layer.style.fillDataMax;
            return val;
          })
          .attr("data-pcode", function(d){
            return d.properties.ADM2_PCODE;
          })
          .attr("data-id", function(d){
            return layer.style.fillColumn;
          })
          .attr("d", pathGenerator)
          .attr("data-fill", function(d){
            const val = d.properties[layer.style.fillColumn];
            if (layer.style.fillType === 'single') return rgba(layer.style.fill);
            if (layer.style.fillType === 'graduated' && val === 0) {return 'rgba(255,255,255,0.2)';}
            if (layer.style.fillType === 'graduated') {return layer.colorScale(val) || 'rgba(255,255,255,0.2)';}
            if (layer.style.fillPalette === 'IPC') {return layer.colorScale(val) || 'rgba(255,255,255,0.2)';}
          })
          .attr("fill", function(d){
            const val = d.properties[layer.style.fillColumn];
            if (layer.style.fillType === 'single') return rgba(layer.style.fill);
            if (layer.style.fillType === 'graduated' && val === 0) {return 'rgba(255,255,255,0.2)';}
            if (layer.style.fillType === 'graduated') {return layer.colorScale(val) || 'rgba(255,255,255,0.2)';}
            if (layer.style.fillPalette === 'IPC') {return layer.colorScale(val) || 'rgba(255,255,255,0.2)';}
          })
          .attr("stroke", function(){
            return rgba(layer.style.stroke);
          })
          .attr("opacity", function(){
            return layer.opacity;
          })
          .attr("stroke-width", function(){
            return layer.style.strokeWidth;
          });

        if (layer.class === 'gridAdm2'){
        poly.on('mouseover', function(d) {
            var classList = d3.select(this).attr('class').split(/\s+/);
            d3.selectAll('.' + classList[1]+'x:not(.gridAdm2x.'+classList[2]+')').attr('filter', '');
            d3.selectAll('.gridAdm2x.' + classList[2]).attr('filter', 'url(#innerShadow)');
            var data = d.srcElement.__data__.properties;
            activeAdm2 = data;
            columns.forEach(function(column){
              d3.select('.legendScaleMarker'+column.id).style('width', function(d){ 
                var max = parseInt(d3.select('.legendScaleMarker'+column.id).attr('data-max'));
                return ((data[column.id]/max) * 99.87 || 0) + '%';
              }).style('opacity', function(d){
                var max = parseInt(d3.select('.legendScaleMarker'+column.id).attr('data-max'));
                var v = data[column.id]/max || 0;
                if ((v/max) > 0) return 1;
                return 0;
              });
              let background = {opacity: 1, backgroundColor: column.color, color: '#FFF'};

              if (column.id === 'overall_phase') {
                let c = ipcColorArray[activeAdm2[column.id]-1];
                background.backgroundColor = c;
                if (activeAdm2[column.id] <= 3) background.color = '#000';
                if (activeAdm2[column.id] >= 4) background.color = '#FFF';
              }
              d3.select('.legendScaleMarkerVal'+column.id).style('color', background.color).style('opacity', 1).style('background-color', background.backgroundColor).html(numberFormatter(data[column.id]) || '-')

            });
            // adm1 hover
            const adm1Pcode = data.ADM1_PCODE;
            d3.selectAll('.gridAdm1').attr('filter', '');
            d3.selectAll('.gridAdm1'+adm1Pcode).attr('filter', '');
            d3.selectAll('.gridAdm1'+adm1Pcode).attr('stroke-width', 2.5);
            d3.selectAll('.gridAdm1'+adm1Pcode).attr('stroke', 'rgba(0,0,0,0.5)');
          })
          .on('mouseout', function(d) {
            var classList = d3.select(this).attr('class').split(/\s+/);
            d3.selectAll('.gridAdm2x').attr('filter', '');
            d3.selectAll('.legendScaleMarker').style('opacity', 0);
            d3.selectAll('.legendScaleMarkerVal').style('opacity', 0);
            activeAdm2 = null;
            d3.selectAll('.gridAdm1').attr('filter', '').attr('stroke-width', 0.5);
          })
        }

        // Update selection: Update existing paths
        paths.attr("d", pathGenerator);

        // Exit selection: Remove paths that are no longer in the data
        paths.exit().remove();
      }

      if (layer.type === 'line'){
        const g = svg.append('g').attr('class', 'layer lineLayer');
        // Bind data to paths
        const paths = g.selectAll(".layer"+layer.name.replace(/[^\w]/g, ''))
        .data(layer.data.features, feature => feature.properties[layer.labelColumn]); // Use a key function for object constancy
        
        let className = "layer"+layer.name.replace(/[^\w]/g, '');
        if (layer.class) className += " " + layer.class;

        // Enter selection: Create new path elements for new data
        var poly = paths.enter()
          .append("path")
          .attr("class", 'line')
          .attr("d", pathGenerator)
          .attr("stroke", function(){
            return rgba(layer.style.stroke);
          })
          .attr('fill', 'none')
          .attr("opacity", function(){
            return layer.opacity;
          })
          .attr("stroke-width", function(){
            return layer.style.strokeWidth * 2;
          })
          .attr('stroke-dasharray', +layer.style.dashSpacing +' '+layer.style.dashSpacing)

        // Update selection: Update existing paths
        paths.attr("d", pathGenerator);

        // Exit selection: Remove paths that are no longer in the data
        paths.exit().remove();
      }
    });
    // Cleanup function
    return () => {
      svg.selectAll('g.layer').remove();
    };

  }, [layers, width, height, center, zoom, style, projectionType]);

  const legendRows = useMemo(() => {
    if (!layers) return;
    let legendArr = [];
   
    // Sort layers by zIndexfs
    layers.sort((a, b) => a.zIndex - b.zIndex);

    // draw layers
    layers.forEach(d => {
        // legend 
        if ((d.style.fillType === 'graduated') && (d.style.fillPalette !== 'IPC')) {
          const legendPolygonRow = (
            <div>
              <div className={styles.legendPolygonGraduated} style={{ opacity: d.opacity }}>
                <ColorScale
                  colorScale={d.style.fillPalette}
                  steps={d.style.fillSteps}
                  colorScaleType={d.style.fillScaleType}
                  pow={d.style.fillPow}
                  height={13}
                  containerClass="colorScaleDiv"
                  inverted={d.style.fillScaleInvert}
                />
                <div data-max={d.style.fillDataMax} style={{position: 'relative', opacity: 0, left: 0, zIndex: 999, top: -14}} className={`legendScaleMarker legendScaleMarker${d.style.fillColumn}`}>
                  <div className="scaleMark" style={{borderRight: '1px solid transparent', height: 14, margin: '0px', textAlign: 'right', whiteSpace: 'nowrap'}}>
                    <img src={downIcon} style={{width: 13, height: 12, position: 'absolute', top: -5, right: -6}}/>
                    <img src={downIcon} style={{width: 13, transform: 'scaleY(-1)', height: 12, position: 'absolute', top: 6.5, right: -6}}/>
                  </div>
                </div>
              </div>
              <div className={styles.legendPolygonScaleUnits}>
                <div className={styles.legendPolygonScaleUnit1}>
                  {d.style.fillDataMin || ''}
                </div>
                {d.style.fillDataMax > 0 && (
                  <div className={styles.legendPolygonScaleUnit2}>
                    {numberFormatter(d.style.fillDataMax)}
                  </div>
                )}
              </div>
            </div>

          );
          const row = d.visible > 0 && d.showInLegend > 0 && (
            <div key={`legendPolygon${d.id}`}>
              <div className={styles.legendSeriesTitle}>
                <b style={{ fontSize: 10 }}>{d.legendSeriesTitle}</b>
                <div className={`legendScaleMarkerVal legendScaleMarkerVal${d.style.fillColumn}`} style={{opacity: 0, margin: '0px', textAlign: 'right', display: 'inline-block', marginLeft: 4, borderRadius: 0, fontSize: 11, fontWeight: 'bold', lineHeight: 1.2, color: '#FFF', padding: '0px 2px', backgroundColor: d.primaryColor }}>0</div>
              </div>
              {legendPolygonRow}
            </div>
          );
          legendArr.push(row);
        }
        if (d.style.fillPalette === 'IPC') {
          const legendPolygonRow = (
            <div>
              <div className={styles.legendPolygonGraduated} style={{ opacity: d.opacity }}>
                <ColorScale
                  colorScale={d.style.fillPalette}
                  steps={d.style.fillSteps}
                  colorScaleType={d.style.fillScaleType}
                  pow={d.style.fillPow}
                  height={13}
                  containerClass="colorScaleDiv"
                  inverted={d.style.fillScaleInvert}
                />
                <div data-max={d.style.fillDataMax} style={{opacity: 0, position: 'relative', left: 'calc(50% / -5)', zIndex: 999, top: -14}} className={`legendScaleMarker legendScaleMarker${d.style.fillColumn}`}>
                  <div className="scaleMark" style={{borderRight: '1px solid transparent', height: 14, margin: '0px', textAlign: 'right', whiteSpace: 'nowrap'}}>
                    <img src={downIcon} style={{width: 13, height: 12, position: 'absolute', top: -5, right: -6}}/>
                    <img src={downIcon} style={{width: 13, transform: 'scaleY(-1)', height: 12, position: 'absolute', top: 6.5, right: -6}}/>
                  </div>
                </div>
              </div>
              <div className={styles.legendPolygonScaleUnits}>
                <div className={styles.legendPolygonScaleUnit1} style={{marginLeft: 'calc(50% / 5)'}}>
                  {d.style.fillDataMin || ''}
                </div>
                {d.style.fillDataMax > 0 && (
                  <div className={styles.legendPolygonScaleUnit2} style={{marginRight: 'calc(50% / 5)'}}>
                    {numberFormatter(d.style.fillDataMax)}
                  </div>
                )}
              </div>
            </div>

          );
          
          const row = d.visible > 0 && d.showInLegend > 0 && (
            <div key={`legendPolygon${d.id}`}>
              <div className={styles.legendSeriesTitle}>
                <b style={{ fontSize: 10 }}>{d.legendSeriesTitle}</b>
                <div className={`legendScaleMarkerVal legendScaleMarkerVal${d.style.fillColumn}`} style={{opacity: 0, margin: '0px', textAlign: 'right', display: 'inline-block', marginLeft: 4, borderRadius: 0, fontSize: 11, fontWeight: 'bold', lineHeight: 1.2, color: '#FFF', padding: '0px 2px', backgroundColor: d.primaryColor }}>0</div>
              </div>
              {legendPolygonRow}
            </div>
          );
          legendArr.push(row);
        }
      });
      return legendArr;
  }, [layers, style]); 

  return (
    <div>
      <div style={{position: 'relative',zIndex: 888, width: '100%', height: '100%' }}>
        <svg id={id} data-id={style.fillColumn} ref={ref} viewBox="-60 0 900 675" preserveAspectRatio="xMidYMid">
        <defs>
          <filter id="innerShadow">
          <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0 
            50 20 70 0 0 
            0 0 0 40 0 
            0 0 50 9 0" result="opaque-source"/>
          <feGaussianBlur stdDeviation="4.4"/>
          <feOffset dy="0"/>
          <feComposite operator="xor" in2="opaque-source"/>
          <feComposite operator="in" in2="opaque-source"/>
          <feComposite operator="over" in2="SourceGraphic"/>
          </filter>
        </defs>
        </svg>
        <div className={styles.gridBarLegend} style={{position: 'absolute', padding: '0px 5px', left: 15, top: -63, backgroundColor: 'white', width: 'calc(100% - 60%)', minWidth: 100, marginTop: 4, marginLeft: '30%', marginRight: '30%', display: 'inline-block'}}>{legendRows}</div>
      </div>
    </div>
  )  
};

export default SudanD3Map;