import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import openExternal from './open_external.svg';
import styles from './Documents.module.css';
import doc5 from '../../projects/SudanDashboard/documents/202401_shelter_report.jpg';
import doc4 from '../../projects/SudanDashboard/documents/202401_information_landscape.jpeg';
import doc3 from '../../projects/SudanDashboard/documents/document3.jpg';
import doc2 from './deep_export.svg';
import doc1 from '../../projects/SudanDashboard/documents/20231122_access_analysis.png';
import doc0 from '../../projects/SudanDashboard/documents/20231031_situation_analysis.png';

const rows = [
  {
    title: 'Sudan Crisis: Shelter Thematic Report',
    type: 'Analysis',
    date: '31 January 2024',
    author: 'iMMAP Inc., DFS, Shelter Cluster',
    description: 'The report aims to analyze the secondary impacts of shelter issues in Sudan and discuss possible solutions leveraging existing capacities.',
    download: 'https://immap.org/product/sudan-crisis-shelter-thematic-report-january-2024/',
    thumbnail: doc5,
  },
  {
    title: 'Sudan Crisis: Information Landscape',
    type: 'Analysis',
    date: '19 January 2024',
    author: 'iMMAP Inc., DFS',
    description: 'This report aims to provide an overview of the data landscape for the current humanitarian crisis in Sudan.',
    download: 'https://immap.org/product/sudan-crisis-information-landscape-january-2024/',
    thumbnail: doc4,
  },
  {
    title: 'Sudan Crisis: Situational Analysis',
    type: 'Analysis',
    date: '18 December 2023',
    author: 'iMMAP Inc., DFS',
    description: 'This report, spanning October to November 2023, details sustained hostilities between the Rapid Support Forces (RSF) and Sudanese Armed Forces (SAF) across Sudan, particularly affecting Khartoum and Darfur.',
    download: 'https://immap.org/product/situational-analysis-of-sudans-crisis-december-2023/',
    thumbnail: doc3,
  },
  {
    title: 'DEEP extracts related to the Sudan 2023 Situational Analyses',
    type: 'Dataset',
    date: '8 December 2023',
    author: 'DEEP, DFS',
    description: 'Monthly DEEP extracts related to the Sudan Situational Analyses conducted by DFS and iMMAP Inc. They are provided by sector and operational environment themes.',
    download: 'https://data.humdata.org/dataset/deep-extracts-sudan-2023-situational-analyses',
    thumbnail: doc2,
  },
  {
    title: 'Sudan: Cross-Border Humanitarian Access Analysis',
    type: 'Analysis',
    date: '22 November 2023',
    author: 'iMMAP Inc.',
    description: 'This report provides an overview of the conflict dynamics around Chad–Sudan and South Sudan–Sudan Border Cross Points (BCPs) along with mapping the access routes into Sudan for humanitarian agencies. It also outlines the trajectory of the mentioned border areas.',
    download: 'https://reliefweb.int/report/sudan/sudan-cross-border-humanitarian-access-analysis-22-november-2023',
    thumbnail: doc1,
  },
  {
    title: 'Sudan Crisis: Situational Analysis',
    type: 'Analysis',
    date: '31 October 2023',
    author: 'iMMAP Inc., DFS',
    description: 'The document presents a comprehensive overview of the situation in Sudan, where an ongoing conflict has led to a substantial loss of life, primarily attributed to devastating bombings in Khartoum. The report covers developments in the time period of August-September 2023, but provides background information since the conflict outbreak.',
    download: 'https://immap.org/product/situational-analysis-of-sudans-crisis-october-2023/',
    thumbnail: doc0,
  },
];

export default function BasicTable() {
  const [pg] = React.useState(0);
  const [rpg] = React.useState(6);

  return (
    <div className={styles.container}>
      <Paper className={styles.documents} style={{ backgroundColor: 'unset', position: 'relative' }}>
        <div className={styles.divTitle}>
          Latest Documents
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, maxWidth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow key="header">
                <TableCell />
                <TableCell align="left" />
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Author</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell className={styles.hideMobile} align="left">Description</TableCell>
                <TableCell style={{ paddingRight: 11, paddingLeft: 3 }} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(pg * rpg, pg * rpg + rpg).map((row) => (
                <TableRow
                  key={row.title + row.date}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <a target="_blank" href={row.download} rel="noreferrer" aria-label="Download">
                      <img className={styles.thumnbail} src={row.thumbnail} alt="Download" />
                    </a>
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'noWrap',
                      paddingLeft: 0,
                      color: '#000',
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    <a target="_blank" aria-label="Download" rel="noreferrer" href={row.download}>
                      <span>{row.title}</span>
                    </a>
                  </TableCell>

                  <TableCell style={{ whiteSpace: 'noWrap' }} align="center">{row.date}</TableCell>
                  <TableCell style={{ whiteSpace: 'noWrap' }} align="left">{row.author}</TableCell>
                  <TableCell style={{ whiteSpace: 'noWrap' }} align="left">{row.type}</TableCell>
                  <TableCell className={styles.hideMobile} style={{ color: '#666' }} align="left">
                    <div>
                      {row.description}
                    </div>
                  </TableCell>
                  <TableCell style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 16 }} align="right">
                    <a target="_blank" aria-label="Download" rel="noreferrer" href={row.download}>
                      <img className={styles.open} src={openExternal} height={14} alt="Download" />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
