import moment from 'moment';

export function numberFormatter(number) {
  if (number === '-') return '-';
  const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
  const result = formatter.format(number);
  if (result === 'NaN') return '-';
  return result;
}

export function getDaysArray(startDate, sd) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(sd);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function convertRGBtoRGBA(rgbString, alpha) {
  if (!rgbString) return 'rgba(0,0,0,0)';
  const rgbValues = String(rgbString).match(/\d+/g);
  const [red, green, blue] = rgbValues;
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export default numberFormatter;
