import {
  useState, useEffect, useMemo, useId, useCallback,
} from 'react';
import { createTheme } from '@mui/material/styles';
import { useWindowSize } from '@uidotdev/usehooks';
import { KPIs, LineChart } from '@the-deep/reporting-module-components';
import '@the-deep/reporting-module-components/build/esm/index.css';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Tooltip } from 'react-tooltip';
import { Tabs as ArkTabs } from '@ark-ui/react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Slider from '@mui/material/Slider';
import * as htmlToImage from 'html-to-image';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import * as d3 from 'd3';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import Documents from '../components/Documents/Documents';
import MapVizzard from '../components/MapVizzard';
import saveFile from '../components/MapVizzard/saveFile';
import { numberFormatter, getDaysArray } from '../components/Map/Helpers';
import SudanNav from './SudanNav';
import SudanMapGrid from './SudanMapGrid';
import {parsedData, dtmTime} from './SudanDataParser';
import embedIcon from './SudanDashboard/images/embed.svg';
import heatmapIcon from './SudanDashboard/images/heatmap.svg';
import hexbinIcon from './SudanDashboard/images/hexbin.svg';
import airIcon from './SudanDashboard/images/explosion.svg';
import adm1Icon from './SudanDashboard/images/adm1.svg';
import adm2Icon from './SudanDashboard/images/adm2.svg';
import adm1BlueIcon from './SudanDashboard/images/adm1_blue.svg';
import adm2BlueIcon from './SudanDashboard/images/adm2_blue.svg';
import attackIcon from './SudanDashboard/images/attack.svg';
import violenceIcon from './SudanDashboard/images/violence.svg';
import propertyIcon from './SudanDashboard/images/property.svg';
import warningIcon from './SudanDashboard/images/warning.svg';
import gridIcon from './SudanDashboard/images/grid.svg';
import './SudanDashboard/style/variables.css';
import styles from './SudanDashboard/style/SudanDashboard.module.css';

let totalRefugees = 0;
let minDate;
let maxDate;
let acledFeatures;
let idpFeaturesAdm1;
let idpFeaturesAdm2;
const adm1Array = [];
const adm2Array = [];
let foodSecurityAdm1CentroidFeatures;
let adm2Features;
let maxRefugeesDate;
let maxDtmDate;
let ipcDate;
let ipcDateRange;
let protHotspotDate;

parsedData.layers.forEach((layer) => {
  if (layer.name === 'SDN_ADM2.geojson') {
    ipcDate = moment(layer.data.features[0].properties.analysis_date).format('YYYY-MM-DD');
    ipcDateRange = layer.data.features[0].properties.current_period_dates;
    protHotspotDate = layer.data.features[0].properties.prot_hotspot_data_date;
  }
  if ((layer.name === 'SDN_ADM2.geojson') || (layer.name === 'SDN_ADM2_centroids')) {

    layer.data.features.forEach((feature) => {
      if (layer.name === 'SDN_ADM2.geojson') {
        adm2Array.push({ name: feature.properties.ADM2_EN, pcode: feature.properties.ADM2_PCODE, adm1_pcode: feature.properties.ADM1_PCODE });
      }
    });
  }

  if (layer.name === 'SDN_ADM2_centroids') {
    idpFeaturesAdm2 = structuredClone(layer.data);
  }

  if (layer.name === 'IPC_ADM1_centroids') {
    foodSecurityAdm1CentroidFeatures = structuredClone(layer.data.features);
  }
  if (layer.name === 'SDN_ADM1_centroids') {
    layer.data.features.forEach((feature) => {
      adm1Array.push({ name: feature.properties.ADM1_EN, pcode: feature.properties.ADM1_PCODE });
    });
    idpFeaturesAdm1 = layer.data;
  }
  if (layer.name === 'Refugees') {
    totalRefugees = d3.sum(layer.data, (d) => d.refugee_n);
    maxRefugeesDate = d3.max(layer.data, (d) => d.date);
  }

  if (layer.name === 'ACLED EVENTS') {
    minDate = d3.min(layer.data, (d) => d.event_date);
    maxDate = d3.max(layer.data, (d) => d.event_date);
    acledFeatures = layer.data;
  }
  if ((layer.name === 'SDN_ADM2.geojson')) {
    adm2Features = structuredClone(layer.data.features);
  }
});

let idpTimeGrouped = d3.rollups(dtmTime,(d) => 1, (d) => d.date)
.sort(([a, ], [b, ]) => d3.descending(a, b))

function SudanDashboard() {
  const location = useLocation();
  const size = useWindowSize();

  let initSubView = 0;
  if (location.pathname.includes('khartoum')) initSubView = 4;
  if (location.pathname.includes('darfur')) initSubView = 2;
  if (location.pathname.includes('kordofan')) initSubView = 3;
  if (location.pathname.includes('ajjazirah')) initSubView = 1;
  let tab = 0;
  if (location.pathname.includes('displacement')) {
    tab = 1;
  }
  if (location.pathname.includes('food-security')) {
    tab = 2;
  }
  if (location.pathname.includes('protection')) {
    tab = 3;
  }
  if (location.pathname.includes('grid')) {
    tab = 10;
  }
  if (location.pathname.includes('documents')) {
    tab = 11;
  }

  let gridLayers = parsedData.layers.filter((layer) => (layer.name.includes('Disputed boundaries') || layer.name === 'SDN_ADM0.geojson' || layer.name === 'SDN_ADM1.geojson' || layer.name === 'SDN_ADM2.geojson'));
  let grid = { mapOptions: { ...parsedData.mapOptions }, layers: [...gridLayers] };
  grid = Object.assign({}, grid);
  grid.mapOptions.showOverview = false;
  grid.mapOptions.enableDragPan = false;
  grid.mapOptions.showScale = false;
  grid.mapOptions.enableDoubleClickZoom = false;
  grid.mapOptions.showFooter = false;
  grid.mapOptions.sources = '';

  const [subView, setSubView] = useState(initSubView);
  const subViewArr = ['', 'ajjazirah', 'darfur', 'kordofan', 'khartoum'];
  const [mainView, setMainView] = useState('');
  const [mapConfig, setMapConfig] = useState(parsedData);
  const [gridConfig, setGridConfig] = useState(grid);
  const [gridSort, setGridSort] = useState(['acled_events', 'acled_fatalities', 'idp_n', 'overall_phase', 'p3plus', 'estimated_population', 'idp_increases', 'idp_decreases']);
  const [tabIndex, setTabIndex] = useState(tab);
  const [numCols, setNumCols] = useState('auto');

  const [acledEventType, setAcledToggle] = useState([]);
  const [acledActorToggle, setAcledEventType] = useState([]);
  const [adm1Filter, setAdm1Filter] = useState([]);
  const [adm1List, setAdm1List] = useState(adm1Array);
  const [adm2Filter, setAdm2Filter] = useState([]);
  const [adm2List, setAdm2List] = useState(adm2Array);
  const [admToggle, setAdmToggle] = useState('adm1');
  const [conflictToggle, setConflictToggle] = useState('heatmap');
  const [weighted, setWeighted] = useState(false);
  const [monthData, setMonthData] = useState();
  const [isMobile, setIsMobile] = useState(false);

  const daysArray = getDaysArray(minDate, maxDate);
  const daysArrayTs = daysArray.map((d) => moment(d, 'YYYY-MM-DD').unix());

  var monthObj = [];
  var date = moment(minDate).startOf('month');
  while (date < moment(maxDate).endOf('month')) {
      monthObj.push({'date': date.format('YYYY-MM'), 'label': date.format('MMM').toUpperCase() });
      date.add(1,'month');
  };

  const startDate = daysArray.findIndex((d) => d === '2024-01-01');
  const [dateRange, setDateRange] = useState([startDate, daysArray.length - 1]);
  const [dateRangeStr, setDateRangeStr] = useState(`${moment(daysArray[dateRange[0]]).format('DD MMM')}    -    ${moment(daysArray[dateRange[1]]).format('DD MMM YYYY')}`);
  const [dateRangeStr2, setDateRangeStr2] = useState(`${moment(daysArray[dateRange[0]]).format('DD MMMM')}    -    ${moment(daysArray[dateRange[1]]).format('DD MMMM YYYY')}`);
  const [fatalities, setFatalities] = useState('-');
  const [incidents, setIncidents] = useState('-');
  const [totalIDPs, setTotalIDPs] = useState('-');
  const [totalOccupiedSchools, setTotalOccupiedSchools] = useState('-');
  const [totalP3Plus, setTotalP3Plus] = useState('-');
  const [averageIPC, setAverageIPC] = useState('-');
  const [totalPopulation, setTotalPopulation] = useState('-');
  const [totalIDPChange, setTotalIDPChange] = useState('-');
  const [totalIDPIncreases, setTotalIDPIncreases] = useState('-');
  const [totalIDPDecreases, setTotalIDPDecreases] = useState('-');
  const [totalExcludedFromHeatMap, setTotalExcludedFromHeatmap] = useState(0);
  const [totalFatalitiesExcludedFromHeatmap, setTotalFatalitiesExcludedFromHeatmap] = useState(0);
  const [idpsByDate, setIdpsByDate] = useState([]);
  const [radius, setRadius] = useState(4);
  const [hexRadius, setHexRadius] = useState(4);
  const printPNGId = useId();
  const queryParameters = new URLSearchParams(location.search);
  const print = queryParameters.get('print') || false;
  const dev = queryParameters.get('dev') || false;
  const mapId = queryParameters.get('mapId') || false;
  let paddingBottom = 0;
  if (print) paddingBottom = 0;

  // actor select box (mobile)
  const actors = [
    { code: 'is_sudan_state', name: 'State Armed Forces, Sudan' },
    { code: 'is_rsf', name: 'Rapid Support Forces' },
    { code: 'is_nsag', name: 'Non-state Armed Group' },
    { code: 'is_militia', name: 'Militia' },
    { code: 'are_civilians', name: 'Civilians' },
    { code: 'are_aid_actors', name: 'Aid Actors' },
  ];

  let sliderMarginRight = 0;
  let daysDiff = moment(maxDate).diff(moment(maxDate).endOf('month'), 'days');
  sliderMarginRight = (Math.abs(daysDiff + 1.5) / (daysArray.length)) * 100 + '%';

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const Adm1MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 173,
        maxWidth: 173,
        borderRadius: 0,
        marginLeft: 18,
        marginTop: 5,
      },
    },
  };

  const GridColsMenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 73,
        maxWidth: 73,
        borderRadius: 0,
        marginLeft: 30,
        marginTop: 5,
      },
    },
  };

  const filteredData = useMemo(() => {
    // var newData = structuredClone(mapConfig);
    var newData = Object.assign({}, newData)
    // setMapConfig(newData);
  }, [subView, adm1Filter, adm2Filter])

  const kpiData = {
    kpis: [
      {
        title: 'Political Violence Incidents',
        subtitle: dateRangeStr2,
        source: 'ACLED',
        url: 'https://acleddata.com/',
        date: moment(maxDate).format('YYYY-MM-DD'),
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: '#be2126',
        value: numberFormatter(incidents),
        visible: 1,
        grid: 1,
        id: 'acled_events'
      },
      {
        title: 'Fatalities',
        subtitle: dateRangeStr2,
        source: 'ACLED',
        url: 'https://acleddata.com/',
        date: moment(maxDate).format('YYYY-MM-DD'),
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: '#be2126',
        value: numberFormatter(fatalities),
        visible: 1,
        grid: 1,
        id: 'acled_fatalities'
      },
      {
        title: 'Internally Displaced Persons (IDPs)',
        subtitle: 'estimated as of '+idpTimeGrouped[0][0].format('DD MMM YYYY'),
        source: 'DTM, IOM',
        url: 'https://dtm.iom.int/sudan',
        date: idpTimeGrouped[0][0].format('YYYY-MM-DD'),
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: '#418FDE',
        value: numberFormatter(totalIDPs),
        visible: 1,
        grid: 1,
        id: 'idp_n'
      },
      {
        title: 'IDPs increases',
        subtitle: 'between ' + idpTimeGrouped[1][0].format('DD MMM YYYY') + ' - ' + idpTimeGrouped[0][0].format('DD MMM YYYY'),
        source: 'DTM, IOM',
        url: 'https://dtm.iom.int/sudan',
        date: idpTimeGrouped[0][0].format('YYYY-MM-DD'),
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: '#418FDE',
        value: numberFormatter(totalIDPIncreases),
        visible: 0,
        grid: 1,
        id: 'idp_increases'
      },
      {
        title: 'IDPs decreases',
        subtitle: 'between ' + idpTimeGrouped[1][0].format('DD MMM YYYY') + ' - ' + idpTimeGrouped[0][0].format('DD MMM YYYY'),
        source: 'DTM, IOM',
        url: 'https://dtm.iom.int/sudan',
        date: idpTimeGrouped[0][0].format('YYYY-MM-DD'),
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: '#418FDE',
        value: numberFormatter(totalIDPDecreases),
        visible: 0,
        grid: 1,
        id: 'idp_decreases'
      },
      {
        title: 'Sudanese refugees/asylum seekers',
        subtitle: 'in neighboring countries as of '+maxRefugeesDate.format('DD MMM YYYY'),
        source: 'UNHCR',
        url: 'https://data.unhcr.org/en/situations/sudansituation',
        date: maxRefugeesDate.format('YYYY-MM-DD'),
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: 'rgba(25,54,97,1)',
        value: numberFormatter(totalRefugees),
        visible: 1,
        grid: 0,
        id: 'refugees'
      },
      {
        title: 'Food Insecurity Phase',
        subtitle: 'acute food inecurity projection '+ipcDateRange,
        source: 'IPC',
        url: 'https://www.ipcinfo.org/ipc-country-analysis/details-map/en/c/1156730/?iso3=SDN',
        date: ipcDate,
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: 'rgb(229 106 84)',
        value: Math.round(averageIPC),
        visible: 0,
        grid: 1,
        id: 'overall_phase'
      },
      {
        title: 'Population in Phase 3+',
        subtitle: 'food insecurity projection '+ipcDateRange,
        source: 'IPC',
        url: 'https://www.ipcinfo.org/ipc-country-analysis/details-map/en/c/1156730/?iso3=SDN',
        date: ipcDate,
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: 'rgb(229 106 84)',
        value: numberFormatter(totalP3Plus),
        visible: 1,
        grid: 1,
        id: 'p3plus'
      },
      {
        title: 'Total Population',
        subtitle: 'based on official country estimates (IPC)',
        source: 'IPC',
        url: 'https://www.ipcinfo.org/ipc-country-analysis/details-map/en/c/1156730/?iso3=SDN',
        date: ipcDate,
        backgroundColor: '#FFF',
        color: '#000',
        primaryColor: 'grey',
        value: numberFormatter(totalPopulation),
        visible: 1,
        grid: 1,
        id: 'estimated_population'
      }
    ],
  };

  const gridKPIdata = JSON.parse(JSON.stringify(kpiData));
  gridKPIdata.kpis = gridKPIdata.kpis.filter((kpi) => kpi.grid === 1);
  kpiData.kpis = kpiData.kpis.filter((kpi) => kpi.visible === 1);

  const mapvizzard = useMemo(
    () => <MapVizzard mapConfig={mapConfig} iframe id="sudan-2023-dashboard" key="sudanDashboard" dashboard print={print} paddingBottom={paddingBottom} />,
    [mapConfig],
  );

  const mapgrid = useMemo(() => {
      if (tabIndex !== 10) return false;
      return <SudanMapGrid key="mapgrid" tabIndex={tabIndex} numCols={numCols} config={gridConfig} gridSort={gridSort} subView={subViewArr[subView]} adm1Filter={adm1Filter} adm2Filter={adm2Filter} kpiData={gridKPIdata} size={size} />
    },
    [JSON.stringify(gridConfig), tabIndex, size, adm1Filter, adm2Filter, subView, gridKPIdata, gridSort]
  );

  function changeTab(tabId) {
    setTabIndex(tabId);
  }

  const printPNG = useCallback(() => {
    const element = document.getElementById('root');
    const map = document.getElementsByClassName(styles.root);
    map[0].classList.add('printing');
    const scale = 2;
    const filter = (node) => {
      const exclusionClasses = [
        'MuiTabs-root',
        'MuiButton-root',
        styles.embedIframe,
        styles.acledDateRangeSlider,
        styles.acledControls,
        styles.dashboardRadius,
        styles.documents,
        styles.adm1Select,
        styles.subViews,
        // "ol-control",
        'kpi_external_link',
      ];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    };

    htmlToImage.toPng(element, {
      quality: 1,
      cacheBust: false,
      filter,
      height: ((element.clientHeight * scale)),
      width: (element.clientWidth * scale),
      style: { transform: `scale(${scale})`, transformOrigin: 'top left' },
    })
      .then((dataUrl) => {
        saveAs(dataUrl, 'export-map.png');
        map[0].classList.remove('printing');
      });
  });

  function handleDateChange(event, newDateRange) {
    setDateRangeStr(`${moment(daysArray[newDateRange[0]]).format('DD MMM')}    -    ${moment(daysArray[newDateRange[1]]).format('DD MMM YYYY')}`);
    setDateRangeStr2(`${moment(daysArray[newDateRange[0]]).format('DD MMMM')}    -    ${moment(daysArray[newDateRange[1]]).format('DD MMMM YYYY')}`);
    setDateRange(newDateRange);
  }

  function clickMonthHandler(event) {
    const month = moment(event.target.getAttribute('data-date'), 'YYYY-MM');
    const monthStart = moment(month).startOf('month').format('YYYY-MM-DD');
    const monthEnd = moment(month).endOf('month').format('YYYY-MM-DD');
    const newDateRange = [];
    daysArray.forEach((day, i) => {
      if (day === monthStart) newDateRange[0] = i;
      if (day === monthEnd) newDateRange[1] = i;
    });
    if (newDateRange.length === 1) newDateRange[1] = daysArray.length - 1;
    handleDateChange(null, newDateRange);
  }

  const handleWeighted = (_e, newValue) => {
    mapConfig.layers.forEach((layer) => {
      if (layer.name === 'ACLED EVENTS') {
        // eslint-disable-next-line no-param-reassign
        layer.weighted = newValue;
      }
    });
    setWeighted(newValue);
  };

  const handleRadius = () => {
    const newConfig = { ...mapConfig };
    newConfig.layers.forEach((layer) => {
      if (layer.name === 'ACLED EVENTS') {
        // eslint-disable-next-line no-param-reassign
        layer.radius = radius;
      }
    });
    if (tabIndex === 0) setMapConfig(newConfig);
  };

  const handleHexRadius = () => {
    const newConfig = { ...mapConfig };
    newConfig.layers.forEach((layer) => {
      if (layer.name === 'ACLED EVENTS') {
        // eslint-disable-next-line no-param-reassign
        layer.radius = hexRadius;
      }
    });
    if (tabIndex === 0) setMapConfig(newConfig);
  };

  const handleAdmToggle = (a) => {
    changeView(false);
  };

  useEffect(() => {
    handleRadius(radius);
  }, [radius]);

  useEffect(() => {
    handleHexRadius(hexRadius);
  }, [hexRadius]);

  useEffect(() => {
    handleAdmToggle(admToggle);
  }, [admToggle]);

  const foodSecurityTable = useMemo(() => {
    const layerIndex = mapConfig.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = mapConfig.layers[layerIndex];
    var newLayer = Object.assign({}, layer);

    let filteredFeatures = newLayer.data.features;

    if (adm1Filter.length > 0) {
      // eslint-disable-next-line max-len
      filteredFeatures = filteredFeatures.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE));
    }

    if (adm2Filter.length > 0) {
      // eslint-disable-next-line max-len
      filteredFeatures = filteredFeatures.filter((d) => adm2Filter.includes(d.properties.ADM2_PCODE));
    }

    if (subViewArr[subView] === 'ajjazirah') {
      filteredFeatures = filteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD15');
    }

    if (subViewArr[subView] === 'khartoum') {
      filteredFeatures = filteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD01');
    }

    if (subViewArr[subView] === 'darfur') {
      filteredFeatures = filteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));
    }

    if (subViewArr[subView] === 'kordofan') {
      filteredFeatures = filteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
    }

    const phases = {
      phase1_population: 0,
      phase2_population: 0,
      phase3_population: 0,
      phase4_population: 0,
      phase5_population: 0,
      total_population: 0,
      phase1_localities: 0,
      phase2_localities: 0,
      phase3_localities: 0,
      phase4_localities: 0,
      phase5_localities: 0,
    };
    if (filteredFeatures.length === 0) return false;
    filteredFeatures.forEach((d) => {
      phases.phase1_population += d.properties.phase1_population || 0;
      phases.phase2_population += d.properties.phase2_population || 0;
      phases.phase3_population += d.properties.phase3_population || 0;
      phases.phase4_population += d.properties.phase4_population || 0;
      phases.phase5_population += d.properties.phase5_population || 0;
      phases.total_population += d.properties.estimated_population || 0;
      if (d.properties.overall_phase === 1) phases.phase1_localities += 1;
      if (d.properties.overall_phase === 2) phases.phase2_localities += 1;
      if (d.properties.overall_phase === 3) phases.phase3_localities += 1;
      if (d.properties.overall_phase === 4) phases.phase4_localities += 1;
      if (d.properties.overall_phase === 5) phases.phase5_localities += 1;
    });
    
    layer.data = newLayer.data;
    
    const adm1LayerIndex = mapConfig.layers.findIndex((o) => o.name === 'IPC_ADM1_centroids');
    const adm1Layer = mapConfig.layers[adm1LayerIndex];

    let adm1Data = { ...adm1Layer.data };
    let adm1Features = adm1Data.features;
    adm1Features = foodSecurityAdm1CentroidFeatures;

    const foodSecurityAdm1 = d3.rollups(
      filteredFeatures,
      (v) => ({
        p3plus: d3.sum(v, (d) => d.properties.p3plus),
        estimated_population: d3.sum(v, (d) => d.properties.estimated_population),
        p3plus_percentage: (d3.sum(v, (d) => d.properties.p3plus) / d3.sum(v, (d) => d.properties.estimated_population) * 100), // eslint-disable-line
        p1_population: d3.sum(v, (d) => d.properties.phase1_population),
        p2_population: d3.sum(v, (d) => d.properties.phase2_population),
        p3_population: d3.sum(v, (d) => d.properties.phase3_population),
        p4_population: d3.sum(v, (d) => d.properties.phase4_population),
        p5_population: d3.sum(v, (d) => d.properties.phase5_population),
      }),
      (d) => d.properties.ADM1_PCODE,
    );
    const stateRows = [];

    adm1Features.forEach((d) => {
      d.properties.p3plus = 0;
      d.properties.estimated_population = 0;
      d.properties.p3plus_percentage = 0;
      d.properties.p1_population = 0;
      d.properties.p2_population = 0;
      d.properties.p3_population = 0;
      d.properties.p4_population = 0;
      d.properties.p5_population = 0;
      foodSecurityAdm1.forEach((f) => {
        if (f[0] === d.properties.ADM1_PCODE) {
          d.properties = { ...d.properties, ...f[1] };
        }
      });
      if ((d.properties.p3plus) && (d.properties.p3plus > 0)) {
        stateRows.push({
          name: d.properties.ADM1_EN,
          p3plus: d.properties.p3plus,
        });
      }
    });

    adm1Data.features = adm1Features;
    adm1Layer.data = adm1Data;

    stateRows.sort((a, b) => b.p3plus - a.p3plus);

    let rowp3plus = 0;
    if (stateRows[0] && stateRows[0].p3plus) rowp3plus = stateRows[0].p3plus;
    const stateMax = rowp3plus;
    const stateMaxWidth = 110;
    const stateTable = stateRows.map((d) => (
      <div className={styles.foodSecurityStateRow} key={`stateRow${d.pcode}${d.name}row`}>
        <div className={styles.foodSecurityStateName}>{d.name}</div>
        <div
          className={styles.foodSecurityStateBar}
          style={{ width: ((d.p3plus / stateMax) * stateMaxWidth) }}
        />
        <div className={styles.foodSecurityStateValue}>{numberFormatter(d.p3plus)}</div>
      </div>
    ));

    let loader = '';
    if (Number.isNaN(phases.phase1_population / phases.total_population)) loader = styles.loader;
    const p3Plus = phases.phase3_population + phases.phase4_population + phases.phase5_population;
    // setTotalP3Plus(p3Plus);
    // setTotalPopulation(phases.total_population);
    return (
      <div className={loader}>
        <div className={styles.foodSecurityHeaderRow}>
          <div className={styles.foodSecurityHeaderTitle}>IPC Phase</div>
          <div className={styles.foodSecurityHeaderLocalities}>
            Localities
          </div>
          <div className={styles.foodSecurityHeaderPercent}>
            %
          </div>
          <div className={styles.foodSecurityHeaderValue}>
            #
          </div>
          <div className={styles.foodSecurityHeaderPopulation}>
            Population
          </div>
        </div>
        <div className={styles.foodSecurityRow}>
          <div className={styles.foodSecurityBox} style={{ backgroundColor: 'rgba(105, 189, 169, 1)' }} />
          <div className={styles.foodSecurityTitle}>Phase 1</div>
          <div className={styles.foodSecurityLocalities}>
            {phases.phase1_localities}
          </div>
          <div className={styles.foodSecurityPercent}>
            {Math.round((phases.phase1_population / phases.total_population) * 100)}
            %
          </div>
          <div className={styles.foodSecurityValue}>
            {numberFormatter(phases.phase1_population)}
          </div>
        </div>
        <div className={styles.foodSecurityRow}>
          <div className={styles.foodSecurityBox} style={{ backgroundColor: 'rgba(224, 243, 160, 1)' }} />
          <div className={styles.foodSecurityTitle}>Phase 2</div>
          <div className={styles.foodSecurityLocalities}>
            {phases.phase2_localities}
          </div>
          <div className={styles.foodSecurityPercent}>
            {Math.round((phases.phase1_population / phases.total_population) * 100)}
            %
          </div>
          <div className={styles.foodSecurityValue}>
            {numberFormatter(phases.phase2_population)}
          </div>
        </div>
        <div className={styles.foodSecurityRowDotted}>
          <div className={styles.foodSecurityBox} style={{ backgroundColor: 'rgba(254, 221, 141, 1)' }} />
          <div className={styles.foodSecurityTitle}>Phase 3</div>
          <div className={styles.foodSecurityLocalities}>
            {phases.phase3_localities}
          </div>
          <div className={styles.foodSecurityPercent}>
            {Math.round((phases.phase3_population / phases.total_population) * 100)}
            %
          </div>
          <div className={styles.foodSecurityValue}>
            {numberFormatter(phases.phase3_population)}
          </div>
        </div>
        <div className={styles.foodSecurityRow}>
          <div className={styles.foodSecurityBox} style={{ backgroundColor: 'rgba(240, 112, 74, 1)' }} />
          <div className={styles.foodSecurityTitle}>Phase 4</div>
          <div className={styles.foodSecurityLocalities}>
            {phases.phase4_localities}
          </div>
          <div className={styles.foodSecurityPercent}>
            {Math.round((phases.phase4_population / phases.total_population) * 100)}
            %
          </div>
          <div className={styles.foodSecurityValue}>
            {numberFormatter(phases.phase4_population)}
          </div>
        </div>
        <div className={styles.foodSecurityRow}>
          <div className={styles.foodSecurityBox} style={{ backgroundColor: 'rgba(158, 1, 66, 1)' }} />
          <div className={styles.foodSecurityTitle}>Phase 5</div>
          <div className={styles.foodSecurityLocalities}>
            {phases.phase5_localities}
          </div>
          <div className={styles.foodSecurityPercent}>
            {Math.round((phases.phase5_population / phases.total_population) * 100)}
            %
          </div>
          <div className={styles.foodSecurityValue}>
            {numberFormatter(phases.phase5_population)}
          </div>
        </div>
        <div className={styles.foodSecurityTotalP3Pop}>
          <div className={styles.foodSecurityTotalP3Population}>Population in Phase 3+</div>
          <div className={styles.foodSecurityTotalP3PopulationValue}>
            {numberFormatter(p3Plus)}
          </div>
        </div>
        <div className={styles.foodSecurityTotalPop}>
          <div className={styles.foodSecurityTotalPopulation}>Total population</div>
          <div className={styles.foodSecurityTotalPopulationValue}>
            {numberFormatter(phases.total_population)}
          </div>
        </div>
        <div className={styles.foodSecurityBarRow}>
          <div style={{ backgroundColor: 'rgba(105, 189, 169, 0.7)', width: `${(phases.phase1_population / phases.total_population) * 100}%` }} />
          <div style={{ backgroundColor: 'rgba(224, 243, 160, 0.7)', width: `${(phases.phase2_population / phases.total_population) * 100}%` }} />
          <div style={{ backgroundColor: 'rgba(254, 221, 141, 0.7)', width: `${(phases.phase3_population / phases.total_population) * 100}%` }} />
          <div style={{ backgroundColor: 'rgba(240, 112, 74, 0.7)', width: `${(phases.phase4_population / phases.total_population) * 100}%` }} />
          <div style={{ backgroundColor: 'rgba(158, 1, 66, 0.7)', width: `${(phases.phase5_population / phases.total_population) * 100}%` }} />
        </div>
        <div className={styles.foodSecurityBarChart}>
          <div className={styles.foodSecurityBarChartTitle}>Population in Phase 3+ by State</div>
          {stateTable}
        </div>
      </div>
    );
  }, [adm1Filter, adm1List, adm2Filter, adm2List]);

  const protectionTable = useMemo(() => {
    if (tabIndex !== 3) return;
    const layerIndex = mapConfig.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const layer = mapConfig.layers[layerIndex];
    var newLayer = Object.assign({}, layer);
    let filteredFeatures = newLayer.data.features;

    let prot_hotspots = [];
    filteredFeatures.forEach((d) => {
      prot_hotspots = [...prot_hotspots, ...d.properties.prot_hotspot]
    });

    const stateRows = d3.rollups(prot_hotspots, (v) => v.length, (d) => `${d.adm1_en}`);
    const prot_hotspots_Adm2 = d3.rollups(prot_hotspots, (v) => v.length, (d) => `${d.adm2_en} ${d.adm2_pcode}`);

    layer.hoverFn = function(d) { 
      if (tabIndex !== 3) return;

      const hotspotsComment =  document.getElementById('comments');
      if (!hotspotsComment) return;
  
      var hotspotRows = d.values_.prot_hotspot;
      let hotspotCategories = [];
      let hotspotLocations = [];

      var hoverName = `<div><div style="display: inline-block; font-weight: bold;">${d.values_.ADM2_EN}</div><div style="display: inline-block; font-weight: normal; font-size: 12px; margin-left: 5px;">${d.values_.ADM1_EN}</div></div>`;
      var hoverNumHotspots = `<div><div style="display: inline-block; font-weight: bold; color: #295fa0; font-size: 12px;">${hotspotRows.length}</div><div style="display: inline-block; font-weight: normal; font-size: 11px; margin-left: 2px;">protection hotspots</div></div>`;

      hotspotRows.forEach(function(d, i){
        if(!hotspotCategories.includes(d.hotspot_category1) && d.hotspot_category1) {
          var label = `<div style="border-radius: 5px 0px 0px 5px; border-right: 1px solid white; height: 13px; display: inline-block; width: 12px; text-align: center; background-color: #295fa0; color: #FFF; margin-right: 3px;">${i+1}</div>`
          var category = `<div style="display: inline-block; vertical-align: middle; font-size: 10px; margin-right: 5px; background-color: #e8e8e8; border-radius: 5px; padding: 0px 0px; padding-right: 6px;  font-weight: normal;">${label}${d.hotspot_category1}</div>`;
          hotspotCategories.push(category);
        }
        if(!hotspotLocations.includes(d.locations) && d.locations) {
          var category = `<div style="display: inline-block; font-size: 10px; margin-right: 5px; padding: 1px 3px; font-weight: normal;">${d.locations}</div>`;
          hotspotCategories.push(category);    
        }
        if(d.civilian_n) {
          var category = `<div style="display: inline-block; margin-top: 1px; margin-bottom: 5px; font-size: 10px; font-weight: normal; margin-right: 5px; padding: 1px 3px; ;"><img style="vertical-align: middle; padding-bottom: 2px; opacity: 0.7; padding-right: 1px;" width="12px" src="${warningIcon}" alt="" /><span style="color: #295fa0; font-weight: bold;">${numberFormatter(d.civilian_n)}</span> civilians</div>`;
          hotspotCategories.push(category);    
        }
      });
      if (hotspotsComment) hotspotsComment.innerHTML = hotspotCategories.map(category => 
        `<div style="padding-bottom: 1px">${category}</div>`
      ).join('');

      document.getElementById('hoverNumHotspots').innerHTML = hoverNumHotspots;
      document.getElementById('hoverName').innerHTML = hoverName;

    };

    layer.data = newLayer.data;
    
    stateRows.sort((a, b) => b[1] - a[1]);
    
    let rowp3plus = 0;
    if (stateRows[0] && stateRows[0][1]) rowp3plus = stateRows[0][1];
    const stateMax = rowp3plus;
    const stateMaxWidth = 110;
    const stateTable = stateRows.map((d) => (
      <div className={styles.foodSecurityStateRow} key={`stateRow${d[0]}${d[1]}row`}>
        <div className={styles.foodSecurityStateName}>{d[0]}</div>
        <div
          className={styles.protStateBar}
          style={{ width: ((d[1] / stateMax) * stateMaxWidth) }}
        />
        <div className={styles.foodSecurityStateValue}>{numberFormatter(d[1])}</div>
      </div>
    ));

    return (
      <div>
        <div>PROTECTION HOTSPOTS</div>
        <div style={{fontWeight: 'normal', fontSize: 10}}> <span>Protection Cluster Sudan</span><div style={{fontWeight: 'normal', float: 'right', fontSize: 11, paddingRight: 5, color: '#666'}}>
          {protHotspotDate}
        </div></div>

        <div className={styles.foodSecurityTotalPop}>
          <div className={styles.foodSecurityTotalPopulation}>Number of states with hotspots</div>
          <div className={styles.foodSecurityTotalPopulationValue}>
            {numberFormatter(stateRows.length)}
          </div>
        </div>

        <div className={styles.protLocalities}>
          <div className={styles.foodSecurityTotalP3Population}>Number of localities with hotspots</div>
          <div className={styles.protLocalitiesValue}>
            {numberFormatter(prot_hotspots_Adm2.length)}
          </div>
        </div>

        <hr/>

        <div className={styles.foodSecurityBarChart}>
          <div className={styles.foodSecurityBarChartTitle}>Number of localities with protection hotspots</div>
          {stateTable}
        </div>
        
        <div id="hoverName" style={{marginTop: 15}}></div>
        <div id="hoverNumHotspots" style={{marginTop: 5}}></div>

        <div id="comments" style={{marginTop: 10}}></div>
        <div id="hotspotComments" style={{marginTop: 10}}></div>
      </div>
    );
  }, [adm1Filter, adm1List, adm2Filter, adm2List]);

  const barSpark = useMemo(() => {
    if (!monthData) return false;
    const w = 100 / monthObj.length;

    const mScale = d3.scaleTime()
      .domain([moment(minDate), moment(maxDate).endOf('month')])
      .range([0, (100)]);

    const maxH = d3.max(monthData, (d) => d[1]);
    const heightScale = d3.scaleLinear().domain([0, maxH]).range([0, 25]);
    const bars = monthData.map((m) => {
      const md = mScale(moment(m[0]));
      const h = heightScale(m[1]);
      const value = numberFormatter(m[1]);
      const monthLabel = moment(m[0]).format('MMM YYYY');
      const monthStr = moment(m[0]).format('YYYY-MM');
      const className = 'barTooltip monthBar';
      let fill = '#eaafaf';
      // eslint-disable-next-line max-len
      if ((moment(m[0]).unix() >= moment(daysArray[dateRange[0]]).unix()) && (moment(m[0]).unix() <= moment(daysArray[dateRange[1]]).unix())) {
        fill = '#be2126';
      }
      let label;
      if (weighted) { label = 'fatalities'; } else { label = 'incidents'; }
      return (<rect className={className} data-date={moment(m[0]).format('YYYY-MM')} key={`rect${monthLabel}`} onClick={clickMonthHandler} data-tooltip-content={value} data-month={monthLabel} data-label={label} fill={fill} x={md + 1.5} y={0} width={(w - 2)} height={h}>{monthStr}</rect>);
    });

    return (
      <div>
         {!isMobile && (
          <Tooltip
            variant="light"
            className={styles.barTooltipDiv}
            anchorSelect=".barTooltip"
            place="top"
            offset={7}
            noArrow
            style={{ zIndex: 1000 }}
            render={({ content, activeAnchor }) => (
              <div className={styles.tooltipDiv}>
                {activeAnchor?.getAttribute('data-month')}
                <br />
                <div className={styles.tooltipVal}>{content}</div>
                <div className={styles.tooltipLabl}>{activeAnchor?.getAttribute('data-label')}</div>
              </div>
            )}
          />
         )}
        <svg
          height="38"
          width="100%"
          preserveAspectRatio="none"
          viewBox="0 0 100 25"
          style={{
            marginTop: 5, marginBottom: 2, backgroundColor: 'transparent', transform: 'scaleY(-1)',
          }}
        >
          {bars}
        </svg>
      </div>
    );
  }, [monthData]);

  const clickTab = (_e, newValue) => {
    changeTab(newValue);
  };

  const handleSubViewChange = (_e, newValue) => {
    setSubView(newValue);
  };

  const handleRadiusChange = (_e, newValue) => {
    setRadius(newValue);
  };

  const handleHexRadiusChange = (_e, newValue) => {
    setHexRadius(newValue);
  };

  const filterIDPData = (clear = false) => {
    const newConfig = { ...mapConfig };

    // ADM 2 
    let layerIndex = newConfig.layers.findIndex((o) => o.name === 'SDN_ADM2_centroids');
    let layer = newConfig.layers[layerIndex];
    let idpFeaturesClone = { ...idpFeaturesAdm2 };
    let filteredFeatures = idpFeaturesClone.features;

    let idpTime = [ ...dtmTime ];

    if (subViewArr[subView] === 'ajjazirah') {
      filteredFeatures = filteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD15');
      idpTime = idpTime.filter((d) => d.adm1_pcode === 'SD15');
    }

    if (subViewArr[subView] === 'khartoum') {
      filteredFeatures = filteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD01');
      idpTime = idpTime.filter((d) => d.adm1_pcode === 'SD01');
    }

    if (subViewArr[subView] === 'darfur') {
      filteredFeatures = filteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));
      idpTime = idpTime.filter((d) => (d.adm1_pcode === 'SD02' || d.adm1_pcode === 'SD03' || d.adm1_pcode === 'SD04' || d.adm1_pcode === 'SD05' || d.adm1_pcode === 'SD06'));

    }

    if (subViewArr[subView] === 'kordofan') {
      filteredFeatures = filteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
      idpTime = idpTime.filter((d) => (d.adm1_pcode === 'SD07' || d.adm1_pcode === 'SD13' || d.adm1_pcode === 'SD18'));
    }

    if (!clear) {
      if (adm2Filter.length > 0) {
        // eslint-disable-next-line max-len
        filteredFeatures = filteredFeatures.filter((d) => adm2Filter.includes(d.properties.ADM2_PCODE));
        idpTime = idpTime.filter((d) => adm2Filter.includes(d.adm2_pcode));

      }
      if (adm1Filter.length > 0) {
        // eslint-disable-next-line max-len
        filteredFeatures = filteredFeatures.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE));
        idpTime = idpTime.filter((d) => adm1Filter.includes(d.adm1_pcode));
      }
    }

    idpFeaturesClone.features = filteredFeatures;

    const idpAdm2Max = d3.max(filteredFeatures, (d) => d.properties.idp_n);
    const idpDiffAdm2Max = d3.max(filteredFeatures, (d) => Math.abs(d.properties.idp_diff));
    if (tabIndex === 1) layer.scaleDataMax = idpAdm2Max * 1.4;
    layer.style.fillDataMax = idpDiffAdm2Max * 1;
    layer.data = idpFeaturesClone;

    setTotalIDPs(d3.sum(filteredFeatures, (d) => d.properties.idp_n));
    setTotalIDPChange(d3.sum(filteredFeatures, (d) => d.properties.idp_diff));
    setTotalIDPIncreases(d3.sum(filteredFeatures, (d) => d.properties.idp_increases));
    setTotalIDPDecreases(d3.sum(filteredFeatures, (d) => d.properties.idp_decreases));

    // linechart 
    let idpTimeGroup = d3.rollups(idpTime, (v) => d3.sum(v, (d) => d.idp_n), (d) => d.date);

    const idpTimeObj = idpTimeGroup.map((d) => { return {'date': d[0].format('YYYY-MM-DD'), 'value': d[1]}}); 
    setIdpsByDate(idpTimeObj);

    // ADM 1
    const idpsByAdm1 = d3.rollups(layer.data.features, (v) => {
      const idpN = d3.sum(v, (d) => d.properties.idp_n);
      const idpDiff = d3.sum(v, (d) => d.properties.idp_diff);
      return { idp_n: idpN, idp_diff: idpDiff };
    }, (d) => d.properties.ADM1_PCODE);

    layerIndex = newConfig.layers.findIndex((o) => o.name === 'SDN_ADM1_centroids');
    layer = newConfig.layers[layerIndex];

    idpFeaturesClone = { ...idpFeaturesAdm1 };
    filteredFeatures = idpFeaturesClone.features;

    filteredFeatures.forEach((feature) => {
      const f = feature;
      f.properties.idp_n = 0;
      f.properties.idp_diff = 0;
      idpsByAdm1.forEach((geo) => {
        if (geo[0] === f.properties.ADM1_PCODE) {
          // eslint-disable-next-line prefer-destructuring
          f.properties.idp_n = geo[1].idp_n;
          f.properties.idp_diff = geo[1].idp_diff;
        }
      });
    });

    if (subViewArr[subView] === 'ajjazirah') {
      filteredFeatures = filteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD15');
    }
    
    if (subViewArr[subView] === 'khartoum') {
      filteredFeatures = filteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD01');
    }

    if (subViewArr[subView] === 'darfur') {
      filteredFeatures = filteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));
    }

    if (subViewArr[subView] === 'kordofan') {
      filteredFeatures = filteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
    }

    if (!clear) {
      if (adm1Filter.length > 0) {
        // eslint-disable-next-line max-len
        filteredFeatures = filteredFeatures.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE));
      }
    }

    idpFeaturesClone.features = filteredFeatures;
    let idpAdm1Max;
    if(admToggle === 'schools') {
      idpAdm1Max = d3.max(filteredFeatures, (d) => d.properties.occupied_schools);
    } else {
      idpAdm1Max = d3.max(filteredFeatures, (d) => d.properties.idp_n); 
    }

    setTotalOccupiedSchools(d3.sum(filteredFeatures, (d) => d.properties.occupied_schools));

    const idpDiffAdm1Max = d3.max(filteredFeatures, (d) => Math.abs(d.properties.idp_diff));
    if (tabIndex === 1) layer.scaleDataMax = idpAdm1Max * 1.2;
    layer.style.fillDataMax = idpDiffAdm1Max * 1;
    layer.data = idpFeaturesClone;

    if (tabIndex === 1) setMapConfig(newConfig);
  };

  const filterAcledData = (clear = false) => {
    const newConfig = { ...mapConfig };
    const layerIndex = newConfig.layers.findIndex((o) => o.name === 'ACLED EVENTS');
    const layer = newConfig.layers[layerIndex];

    let filteredFeatures = acledFeatures;

    if (acledEventType === 'battles') {
      filteredFeatures = filteredFeatures.filter((d) => d.event_type === 'Battles');
    }
    if (acledEventType === 'explosions') {
      filteredFeatures = filteredFeatures.filter((d) => d.event_type === 'Explosions/Remote violence');
    }
    if (acledEventType === 'violence') {
      filteredFeatures = filteredFeatures.filter((d) => d.event_type === 'Violence against civilians');
    }
    if (acledEventType === 'looting') {
      filteredFeatures = filteredFeatures.filter((d) => d.sub_event_type === 'Looting/property destruction');
    }

    if (acledActorToggle.length > 0) {
      filteredFeatures = filteredFeatures.filter((d) => {
        let row = 0;
        acledActorToggle.forEach((key) => {
          if ((d[key]) && (d[key] === true)) {
            row += 1;
          }
        });
        if (row === acledActorToggle.length) return true;
        return false;
      });
    }

    const filteredFeaturesNoDate = filteredFeatures;
    // eslint-disable-next-line max-len
    filteredFeatures = filteredFeatures.filter((d) => d.event_date_ts >= daysArrayTs[dateRange[0]] && d.event_date_ts <= daysArrayTs[dateRange[1]]);

    let sdnFiltered = filteredFeatures.filter((d) => d.adm0_iso3 === 'SDN');
    let sdnFilteredNoDate = filteredFeaturesNoDate.filter((d) => d.adm0_iso3 === 'SDN');

    if (subViewArr[subView] === 'ajjazirah') {
      sdnFiltered = sdnFiltered.filter((d) => d.adm1_pcode === 'SD15');
      sdnFilteredNoDate = sdnFilteredNoDate.filter((d) => d.adm1_pcode === 'SD15');
    }

    if (subViewArr[subView] === 'khartoum') {
      sdnFiltered = sdnFiltered.filter((d) => d.adm1_pcode === 'SD01');
      sdnFilteredNoDate = sdnFilteredNoDate.filter((d) => d.adm1_pcode === 'SD01');
    }

    if (subViewArr[subView] === 'darfur') {
      sdnFiltered = sdnFiltered.filter((d) => (d.adm1_pcode === 'SD02' || d.adm1_pcode === 'SD03' || d.adm1_pcode === 'SD04' || d.adm1_pcode === 'SD05' || d.adm1_pcode === 'SD06'));
      sdnFilteredNoDate = sdnFilteredNoDate.filter((d) => (d.adm1_pcode === 'SD02' || d.adm1_pcode === 'SD03' || d.adm1_pcode === 'SD04' || d.adm1_pcode === 'SD05' || d.adm1_pcode === 'SD06'));
    }

    if (subViewArr[subView] === 'kordofan') {
      sdnFiltered = sdnFiltered.filter((d) => (d.adm1_pcode === 'SD07' || d.adm1_pcode === 'SD13' || d.adm1_pcode === 'SD18'));
      sdnFilteredNoDate = sdnFilteredNoDate.filter((d) => (d.adm1_pcode === 'SD07' || d.adm1_pcode === 'SD13' || d.adm1_pcode === 'SD18'));
    }

    if (!clear) {
      if (adm1Filter.length > 0) {
        sdnFiltered = sdnFiltered.filter((d) => adm1Filter.includes(d.adm1_pcode));
        sdnFilteredNoDate = sdnFilteredNoDate.filter((d) => adm1Filter.includes(d.adm1_pcode));
        filteredFeatures = filteredFeatures.filter((d) => adm1Filter.includes(d.adm1_pcode));
      }
      if (adm2Filter.length > 0) {
        sdnFiltered = sdnFiltered.filter((d) => adm2Filter.includes(d.adm2_pcode));
        sdnFilteredNoDate = sdnFilteredNoDate.filter((d) => adm2Filter.includes(d.adm2_pcode));
        filteredFeatures = filteredFeatures.filter((d) => adm2Filter.includes(d.adm2_pcode));
      }
    }

    layer.data = filteredFeatures;

    setFatalities(d3.sum(sdnFiltered, (d) => d.fatalities));
    setIncidents(sdnFiltered.length);

    // Write a function to get the count of sdnFiltered where a condition is met
    const excludedFromHeatmap = [...sdnFiltered].filter((d) => d.exclude_from_heatmap === true);
    setTotalExcludedFromHeatmap(excludedFromHeatmap.length);
    const fatalitiesExcludedFromHeatmap = excludedFromHeatmap.filter((d) => d.fatalities > 0);
    // eslint-disable-next-line max-len
    setTotalFatalitiesExcludedFromHeatmap(d3.sum(fatalitiesExcludedFromHeatmap, (d) => d.fatalities));

    let acledByAdm1;
    let acledByAdm2;
    let acledAdm1Max;
    let acledAdm2Max;
    let acledAdm1Title;
    let acledTooltipLabel;
    let acledByMonth;

    // eslint-disable-next-line
    let acledByAdm2Fatalities = d3.rollups(sdnFiltered, (v) => d3.sum(v, (d) => d.fatalities), (d) => d.adm2_pcode);
    const acledByAdm2Incidents = d3.rollups(sdnFiltered, (v) => v.length, (d) => d.adm2_pcode);

    if (weighted) {
      acledByAdm2 = acledByAdm2Fatalities;
      // eslint-disable-next-line
      acledByAdm1 = d3.rollups(sdnFiltered, v => d3.sum(v, d => d.fatalities), d => d.adm1_pcode);
      // eslint-disable-next-line
      acledByMonth = d3.rollups(sdnFilteredNoDate, v => d3.sum(v, d => d.fatalities), d => d.event_date_month);
      acledAdm1Title = 'Political Violence Fatalities';
      acledTooltipLabel = 'fatalities';
    } else {
      acledByAdm2 = acledByAdm2Incidents;
      // eslint-disable-next-line
      acledByAdm1 = d3.rollups(sdnFiltered, v => v.length, d => d.adm1_pcode);
      // eslint-disable-next-line
      acledByMonth = d3.rollups(sdnFilteredNoDate, v => v.length, d => d.event_date_month);
      acledAdm1Title = 'Political Violence Incidents';
      acledTooltipLabel = 'incidents';
    }
    setMonthData(acledByMonth);
    if (conflictToggle === 'adm1') {
      const adm1LayerIndex = newConfig.layers.findIndex((o) => o.name === 'SDN_ADM1_centroids');
      const adm1Layer = newConfig.layers[adm1LayerIndex];
      // eslint-disable-next-line
      acledAdm1Max = d3.max(acledByAdm1, (d) => d[1]);
      if (tabIndex === 0) adm1Layer.scaleDataMax = acledAdm1Max;
      // eslint-disable-next-line
      adm1Layer.legendSeriesTitle = acledAdm1Title;
      if (tabIndex === 0) adm1Layer.tooltipsValueLabel = acledTooltipLabel;
      if (adm1Layer.data.features) {
        adm1Layer.data.features.forEach((feature) => {
          const f = feature;
          f.properties.acled_events = 0;
          acledByAdm1.forEach((geo) => {
            if (geo[0] === f.properties.ADM1_PCODE) {
              // eslint-disable-next-line prefer-destructuring
              f.properties.acled_events = geo[1];
            }
          });
        });
      }
    }

    if (conflictToggle === 'adm2' || mapId === 'sitrep') {
      const adm2LayerIndex = newConfig.layers.findIndex((o) => o.name === 'SDN_ADM2_centroids');
      const adm2Layer = newConfig.layers[adm2LayerIndex];
      acledAdm2Max = d3.max(acledByAdm2, (d) => d[1]);
      if (tabIndex === 0) adm2Layer.scaleDataMax = acledAdm2Max;
      // eslint-disable-next-line
      adm2Layer.legendSeriesTitle = acledAdm1Title;
      if (tabIndex === 0) adm2Layer.tooltipsValueLabel = acledTooltipLabel;
      if (adm2Layer.data.features) {
        adm2Layer.data.features.forEach((feature) => {
          const f = feature;
          f.properties.acled_events = 0;
          acledByAdm2.forEach((geo) => {
            if (geo[0] === f.properties.ADM2_PCODE) {
              // eslint-disable-next-line prefer-destructuring
              f.properties.acled_events = geo[1];
            }
          });
        });
      }
    }

    const newGridConfig = { ...gridConfig };
    const adm2LayerIndex = newGridConfig.layers.findIndex((o) => o.name === 'SDN_ADM2.geojson');
    const adm2Layer = newGridConfig.layers[adm2LayerIndex];
    if (tabIndex === 0) adm2Layer.scaleDataMax = d3.max(acledByAdm2Fatalities, (d) => d[1]);
    // eslint-disable-next-line
    adm2Layer.legendSeriesTitle = acledAdm1Title;
    if (tabIndex === 0) adm2Layer.tooltipsValueLabel = acledTooltipLabel;
    if (adm2Layer.data.features) {
      adm2Layer.data.features.forEach((feature) => {
        const f = { ...feature };
        let acledFatalities = 0;
        let acledEvents = 0;
        acledByAdm2Fatalities.forEach((geo) => {
          if (geo[0] === f.properties.ADM2_PCODE) {
            // eslint-disable-next-line prefer-destructuring
            acledFatalities = geo[1];
          }
        });
        acledByAdm2Incidents.forEach((geo) => {
          if (geo[0] === f.properties.ADM2_PCODE) {
            // eslint-disable-next-line prefer-destructuring
            acledEvents = geo[1];
          }
        });
        f.properties.acled_fatalities = acledFatalities;
        f.properties.acled_events = acledEvents;
        f.properties.idp_increases = 0;
        f.properties.idp_decreases = 0;
        if (f.properties.idp_diff > 0) f.properties.idp_increases = f.properties.idp_diff;
        if (f.properties.idp_diff < 0) f.properties.idp_decreases = Math.abs(f.properties.idp_diff);
      });
    }
    newGridConfig.layers[adm2LayerIndex] = adm2Layer;
    setMapConfig(newConfig);
  };

  // eslint-disable-next-line consistent-return
  const changeView = function (ignoreFilter = false) {
    if (ignoreFilter === false) setAdm1Filter([]);
    if (ignoreFilter === false) setAdm2Filter([]);
    let clone;
    let zoomScale;
    let adm1ListNew;
    let adm2ListNew;
    let foodSecurityAdm1New;
    let foodSecurityAdm2New;
    let adm2IPCFilteredFeatures = [...adm2Features];
    
    // CONFLICT TAB
    if (tabIndex === 0) {
      clone = { ...mapConfig };
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM1_centroids' && conflictToggle !== 'adm1') layer.visible = 0;
        if (layer.name === 'SDN_ADM1_centroids') layer.legendSeriesTitle = 'Political Violence Incidents';
        if (layer.name === 'ACLED EVENTS' && (conflictToggle === 'heatmap' || conflictToggle === 'hexbin')) layer.visible = 1;
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        if (layer.name === 'Refugees') layer.visible = 0;
        if (layer.name === 'Country labels') layer.visible = 1;
        if (layer.name === 'Border crossings - Active') layer.visible = 1;
        if (layer.name === 'Border crossings - Other') layer.visible = 1;
        if (layer.name.includes('Legend Protection')) layer.visible = 0;
        
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillType = 'single';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fill = {"r": 5, "g": 43, "b": 222, "a": 0 };
        if (layer.name === 'SDN_ADM2_centroids' && conflictToggle === 'adm2') {
          layer.visible = 1;
          layer.style.fillType = 'single';
        }
        if (layer.name === 'SDN_ADM2_centroids') {
          layer.tooltipsValueColumn = 'acled_events';
          layer.scaleColumn = 'acled_events';
          layer.style.fill = {"r": 5, "g": 43, "b": 222, "a": 0 }
          layer.style.stroke = {"r": 190, "g": 33, "b": 38, "a": 1 }
          layer.style.fillType = 'single';
        }
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'SDN_ADM1_centroids' && conflictToggle === 'adm1') {
          layer.visible = 1;
          layer.style.fillType = 'single';
        }
        
        if (layer.name === 'SDN_ADM1_centroids') {
          layer.tooltipsValueColumn = 'acled_events';
          layer.scaleColumn = 'acled_events';
          layer.style.fill = {"r": 5, "g": 43, "b": 222, "a": 0 }
          layer.style.stroke = {"r": 190, "g": 33, "b": 38, "a": 1 }
          layer.style.fillType = 'single';
        }
        if (layer.name === 'IPC_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'Dummy legend for IDP fill') layer.visible = 0;
      });
    }
    // DISPLACEMENT TAB
    if (tabIndex === 1) {
      clone = { ...mapConfig };
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'ACLED EVENTS') layer.visible = 0;
        if (layer.name === 'IPC_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'Refugees') layer.visible = 1;
        if (layer.name === 'Country labels') layer.visible = 0;
        if (layer.name === 'Dummy legend for IDP fill') {
          layer.visible = 1;
          layer.legendSeriesTitle = 'IDP increases/decreases ' + idpTimeGrouped[1][0].format('DD MMM YYYY') + ' - ' + idpTimeGrouped[0][0].format('DD MMM YYYY')
        }
        if (layer.name === 'SDN_ADM2.geojson')layer.style.fillType = 'single';
        if (layer.name === 'Border crossings - Active') layer.visible = 1;
        if (layer.name === 'Border crossings - Other') layer.visible = 1;
        if (layer.name.includes('Legend Protection')) layer.visible = 0;
 
        if (admToggle === 'adm1') {
          // eslint-disable-next-line
          if (layer.name === 'SDN_ADM1_centroids') {
            layer.visible = 1;
            layer.legendSeriesTitle = 'Internally Displaced Persons (IDPs)';
            layer.tooltipsValueLabel = 'IDPs';
            layer.tooltipsValueColumn = 'idp_n';
            layer.scaleColumn = 'idp_n';
            layer.style.fill = {"r": 65, "g": 143, "b": 222, "a": 0 }
            layer.style.stroke = {"r": 65, "g": 143, "b": 222, "a": 1 }
            layer.style.fillPaletteNegative = 'PuBu';
            layer.style.fillPalette = 'OrRd';
            layer.style.fillSteps = 6;
            layer.style.fillDataMin = 0;
            layer.style.fillDataMax = 5000;
            layer.style.fillPow = 1;
            layer.style.fillType = 'graduated';
            layer.style.fillColumn = 'idp_diff';
            layer.fillColumn = 'idp_diff';

          }
          // eslint-disable-next-line
          if (layer.name === 'SDN_ADM2_centroids') {
            layer.visible = 0;
          }
        }
        if (admToggle === 'adm2') {
          // eslint-disable-next-line
          if (layer.name === 'SDN_ADM1_centroids') {
            layer.visible = 0;
          }
          // eslint-disable-next-line
          if (layer.name === 'SDN_ADM2_centroids') {
            layer.legendSeriesTitle = 'Internally Displaced Persons (IDPs)';
            layer.scaleColumn = 'idp_n';
            layer.tooltipsValueLabel = 'IDPs';
            layer.tooltipsValueColumn = 'idp_n';
            layer.style.fill = {"r": 65, "g": 143, "b": 222, "a": 0 }
            layer.style.stroke = {"r": 65, "g": 143, "b": 222, "a": 1 }
            layer.style.fillSteps = 6;
            layer.visible = 1;
            layer.style.fillPaletteNegative = 'PuBu';
            layer.style.fillPalette = 'OrRd';
            layer.style.fillType = 'graduated';
            layer.style.fillColumn = 'idp_diff';
            layer.style.fillPow = 1;
            layer.style.fillDataMin = 0;
            layer.style.fillDataMax = 5000;
            layer.fillColumn = 'idp_diff';

          }
        }
        if (admToggle === 'schools') {
          // eslint-disable-next-line
          if (layer.name === 'SDN_ADM1_centroids') {
            layer.visible = 1;
            layer.legendSeriesTitle = 'Schools occupied by IDPs (25 Jan 2024)';
            layer.tooltipsValueLabel = 'Schools occupied by IDPs';
            layer.tooltipsValueColumn = 'occupied_schools';
            layer.scaleColumn = 'occupied_schools';
            layer.scaleDataMax = 700;
            layer.style.fill = {"r": 29, "g": 143, "b": 118, "a": 0.14 }
            layer.style.stroke = {"r": 29, "g": 143, "b": 118, "a": 1 }
            layer.style.fillPaletteNegative = null;
            layer.style.fillPalette = null;
            layer.style.fillType = null;
            layer.style.fillColumn = null;
            layer.fillColumn = null;

          }
          if (layer.name === 'SDN_ADM2_centroids') {
            layer.visible = 0;
          }
        }
      });
    }

    // FOOD SECURITY TAB
    if (tabIndex === 2) {
      clone = { ...mapConfig };
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        if (layer.name === 'IPC_ADM1_centroids') layer.visible = 1;
        if (layer.name === 'Refugees') layer.visible = 0;
        if (layer.name === 'Country labels') layer.visible = 1;
        if (layer.name === 'ACLED EVENTS') layer.visible = 0;
        if (layer.name === 'Dummy legend for IDP fill') layer.visible = 0;
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillType = 'graduated';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillDataMax = 5;
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillDataMin = 1;
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillColumn = 'overall_phase';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillPalette = 'Spectral';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillScaleInvert = true;
        if (layer.name === 'Border crossings - Active') layer.visible = 0;
        if (layer.name === 'Border crossings - Other') layer.visible = 0;
        if (layer.name.includes('Legend Protection')) layer.visible = 0;

      });
    }

    // PROTECTION TAB
    if (tabIndex === 3) {
      clone = { ...mapConfig };
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        if (layer.name === 'IPC_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'Refugees') layer.visible = 0;
        if (layer.name === 'Country labels') layer.visible = 1;
        if (layer.name === 'ACLED EVENTS') layer.visible = 0;
        if (layer.name === 'Dummy legend for IDP fill') layer.visible = 0;
        if (layer.name.includes('Legend Protection')) layer.visible = 1;
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillDataMax = 5;
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillDataMin = 0;
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillType = 'patternCustom';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillColumn = 'prot_hotspot_category';
        if (layer.name === 'SDN_ADM2.geojson') layer.tooltipsValueLabel = 'hotspots';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillPalette = 'Blues';
        if (layer.name === 'SDN_ADM2.geojson') layer.style.fillScaleInvert = false;
        if (layer.name === 'Border crossings - Active') layer.visible = 0;
        if (layer.name === 'Border crossings - Other') layer.visible = 0;
      });
    }

    if (tabIndex === 10) {
      clone = { ...mapConfig };
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        if (layer.name === 'Refugees') layer.visible = 0;
        if (layer.name === 'Country labels') layer.visible = 0;
        if (layer.name === 'Dummy legend for IDP fill') layer.visible = 0;
        if (layer.name.includes('Legend Protection')) layer.visible = 0;
        if (layer.name === 'SDN_ADM2.geojson')layer.style.fillType = 'single';
        
      });
    }

    if (tabIndex === 11) {
      clone = { ...mapConfig };
    }

    if (subViewArr[subView] === 'khartoum') {
      adm1ListNew = adm1Array.filter((d) => d.pcode === 'SD01');
      adm2ListNew = adm2Array.filter((d) => d.adm1_pcode === 'SD01');
       if (adm1Filter.length > 0) {
      // eslint-disable-next-line max-len
      adm2ListNew = adm2Array.filter((d) => adm1Filter.includes(d.adm1_pcode));
    }
      foodSecurityAdm1New = foodSecurityAdm1CentroidFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD01');
      adm2IPCFilteredFeatures = adm2IPCFilteredFeatures.filter((d) => d.properties.ADM1_PCODE === 'SD01');
      
      if (isMobile) {
        zoomScale = d3.scaleSqrt().domain([420, 2500]).range([6.6, 11.0]);
        clone.mapOptions.center = { lon: 33.1, lat: 15.88 };
      } else {
        zoomScale = d3.scaleSqrt().domain([420, 2500]).range([7.9, 11.0]);
        clone.mapOptions.center = { lon: 33.1, lat: 15.88 };
      }

      clone.mapOptions.zoom = zoomScale(size.height || 420);
      
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'capitals.csv') layer.visible = 1;
        if (layer.name === 'State capitals') layer.visible = 1;
        if (layer.name === 'SDN_ADM1.geojson') layer.showLabels = false;
        if (layer.name === 'Refugees') layer.zIndex = 18;
        if (layer.name === 'SDN_ADM2.geojson' && conflictToggle === 'heatmap') layer.showLabels = true;
        if (layer.name === 'SDN_ADM2.geojson' && conflictToggle === 'hexbin') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2.geojson' && conflictToggle === 'adm1') layer.showLabels = true;
        if (layer.name === 'SDN_ADM2_centroids') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2_centroids') layer.labelStyle = 'population';
        if (layer.name === 'SDN_ADM2_centroids') layer.enableTooltips = true;
        if (layer.name === 'Khartoum state') layer.visible = 1;
        if (layer.name === 'Greater Darfur') layer.visible = 0;
        if (layer.name === 'Greater Kordofan') layer.visible = 0;
        if (layer.name === 'ACLED EVENTS' && conflictToggle === 'heatmap') layer.radius = radius;
        if (layer.name === 'ACLED EVENTS' && conflictToggle === 'hexbin') layer.radius = hexRadius;
        if (layer.name === 'Shaded mask') layer.mask = 'POLYGON((0 90,180 90,180 -90,0 -90,-180 -90,-180 0,-180 90,0 90),(31.469062679994916 15.361921401268063,31.08501110042971 16.851953455930328,32.8054757957169 17.147433553163722,33.32834564917678 17.109777466397603,33.96931731409912 17.16681069641298,34.279025499884725 17.08992043958621,34.537820893245794 16.5749824694238,34.455310436270075 15.653823600447609,34.13857962418623 14.892494745514185,33.86243745818903 14.086747742690974,33.68035871430024 13.99071826946151,32.9182883349996 14.087321992300872,31.554576976510827 14.526771717513057,31.66319404548727 15.041602829077519,31.469062679994916 15.361921401268063))';
      });
    } else if (subViewArr[subView] === 'darfur') {
      // GREATER DARFUR VIEW
      adm1ListNew = adm1Array.filter((d) => (d.pcode === 'SD02' || d.pcode === 'SD03' || d.pcode === 'SD04' || d.pcode === 'SD05' || d.pcode === 'SD06'));
      adm2ListNew = adm2Array.filter((d) => (d.adm1_pcode === 'SD02' || d.adm1_pcode === 'SD03' || d.adm1_pcode === 'SD04' || d.adm1_pcode === 'SD05' || d.adm1_pcode === 'SD06'));
      if (adm1Filter.length > 0) {
        // eslint-disable-next-line max-len
        adm2ListNew = adm2Array.filter((d) => adm1Filter.includes(d.adm1_pcode));
      }
      foodSecurityAdm1New = foodSecurityAdm1CentroidFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));
      adm2IPCFilteredFeatures = adm2IPCFilteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD02' || d.properties.ADM1_PCODE === 'SD03' || d.properties.ADM1_PCODE === 'SD04' || d.properties.ADM1_PCODE === 'SD05' || d.properties.ADM1_PCODE === 'SD06'));

      if (isMobile) {
        zoomScale = d3.scaleLog().domain([520, 4000]).range([4.91, 8.99]);
        clone.mapOptions.center = { lon: 25.0, lat: 14.73 };
      } else {
        zoomScale = d3.scaleLog().domain([520, 4000]).range([5.22, 8.99]);
        clone.mapOptions.center = { lon: 25.8, lat: 14.73 };
      }

      clone.mapOptions.zoom = zoomScale(size.height || 420);
      
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'capitals.csv') layer.visible = 1;
        if (layer.name === 'State capitals') layer.visible = 1;
        if (layer.name === 'SDN_ADM1.geojson') layer.showLabels = true;
        if (layer.name === 'SDN_ADM2.geojson') layer.showLabels = false;
        if (layer.name === 'Greater Kordofan') layer.visible = 0;
        if (layer.name === 'Refugees') layer.zIndex = 18;
        if (layer.name === 'SDN_ADM2_centroids') layer.showLabels = false;
        if (layer.name === 'Greater Darfur') layer.visible = 1;
        if (layer.name === 'Khartoum state') layer.visible = 0;
        if (layer.name === 'Shaded mask') layer.mask = 'POLYGON((0 90,180 90,180 -90,0 -90,-180 -90,-180 0,-180 90,0 90),(19.63978189870722 17.579596920771863,18.975906239733614 13.836161176233915,19.188751982732953 10.983103955433634,19.952748423761065 9.099931699942658,20.71729080160739 7.967938132019128,21.33107767351118 7.475198907773219,24.382047414105934 7.209992783605188,26.36564105898691 7.144179369065526,28.13991007014575 6.9491449233722875,29.864267998501113 7.283737954808032,29.99063588389307 8.435909389675075,29.457648705547424 8.838702564138117,28.61213108737734 9.476773565148434,28.309573195268154 12.077403902552888,29.057585089688896 14.104065800905218,28.26088209647688 16.376759847920624,27.927200226740894 20.48009024294575,25.98175102023064 20.45869706653754,23.551503130605067 20.29512242441227,19.63978189870722 17.579596920771863))';
        if (layer.name === 'Country labels') {
          layer.data = [{
            lat: 9.07,
            lon: 21.0,
            name_en: 'Central African Rep.',
            population: 1410858,
          },
          {
            lat: 15.8,
            lon: 20.5,
            name_en: 'Chad',
            population: 1410858,
          },
          {
            lat: 20.7,
            lon: 23.4,
            name_en: 'Libya',
            population: 1410858,
          },
          {
            lat: 8,
            lon: 28.9,
            name_en: 'South Sudan',
            population: 1410858,
          }];
        }
      });
    } else if (subViewArr[subView] === 'kordofan') {
      // GREATER KORDOFAN VIEW
      adm1ListNew = adm1Array.filter((d) => (d.pcode === 'SD07' || d.pcode === 'SD13' || d.pcode === 'SD18'));
      adm2ListNew = adm2Array.filter((d) => (d.adm1_pcode === 'SD07' || d.adm1_pcode === 'SD13' || d.pcode === 'SD18'));
      if (adm1Filter.length > 0) {
        // eslint-disable-next-line max-len
        adm2ListNew = adm2Array.filter((d) => adm1Filter.includes(d.adm1_pcode));
      }
      foodSecurityAdm1New = foodSecurityAdm1CentroidFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
      adm2IPCFilteredFeatures = adm2IPCFilteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD07' || d.properties.ADM1_PCODE === 'SD13' || d.properties.ADM1_PCODE === 'SD18'));
      
      if (isMobile) {
        zoomScale = d3.scaleSqrt().domain([420, 2500]).range([5.19, 9.42]);
        clone.mapOptions.center = { lon: 29.8, lat: 13.25 };
      } else {
        zoomScale = d3.scaleSqrt().domain([420, 2500]).range([5.74, 9.42]);
        clone.mapOptions.center = { lon: 29.8, lat: 13.25 };
      }

      clone.mapOptions.zoom = zoomScale(size.height || 420);
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'capitals.csv') layer.visible = 1;
        if (layer.name === 'SDN_ADM1.geojson') layer.showLabels = true;
        if (layer.name === 'Greater Kordofan') layer.visible = 1;
        if (layer.name === 'SDN_ADM2.geojson') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2_centroids') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2_centroids') layer.enableTooltips = true;
        if (layer.name === 'Refugees') layer.zIndex = 18;
        if (layer.name === 'Greater Darfur') layer.visible = 0;
        if (layer.name === 'Khartoum state') layer.visible = 0;
        if (layer.name === 'State capitals') layer.visible = 1;
        if (layer.name === 'Shaded mask') layer.mask = 'POLYGON((0 90,180 90,180 -90,0 -90,-180 -90,-180 0,-180 90,0 90),(26.34364297341646 14.434374511412187,26.51688300594813 13.232348437292131,26.31979109646955 11.434885202303434,26.46248141993974 10.38220870665829,27.309864991868658 8.925046808208549,28.489714423707134 8.70709415904578,29.513414144473042 8.86208903866644,30.501911931994805 8.770606304971722,31.794380480539726 8.962180635127254,33.19041259159148 11.417233080975194,32.55350882825031 12.797537800807348,32.80907567156813 13.518762639152527,32.62314891074503 14.523753964232839,33.17216507232147 14.52514044285006,34.09382649526627 15.854653361145466,32.72217104997326 16.742322464990608,31.43316754086855 17.191947272316426,26.197828499294946 17.15530552875856,26.34364297341646 14.434374511412187))';
        if (layer.name === 'Country labels') {
          layer.data = [{
            lat: 9,
            lon: 29.2,
            name_en: 'South Sudan',
            population: 1410858,
          }];
        }
      });
    } else if (subViewArr[subView] === 'ajjazirah') {
      // GREATER KORDOFAN VIEW
      adm1ListNew = adm1Array.filter((d) => (d.pcode === 'SD15'));
      adm2ListNew = adm2Array.filter((d) => (d.adm1_pcode === 'SD15'));
      if (adm1Filter.length > 0) {
        // eslint-disable-next-line max-len
        adm2ListNew = adm2Array.filter((d) => adm1Filter.includes(d.adm1_pcode));
      }
      foodSecurityAdm1New = foodSecurityAdm1CentroidFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD15'));
      adm2IPCFilteredFeatures = adm2IPCFilteredFeatures.filter((d) => (d.properties.ADM1_PCODE === 'SD15'));
      
      if (isMobile) {
        zoomScale = d3.scaleSqrt().domain([420, 2500]).range([7.1, 10.2]);
        clone.mapOptions.center = { lon: 33.27, lat: 14.59 };
      } else {
        zoomScale = d3.scaleSqrt().domain([420, 2500]).range([7.4, 10.2]);
        clone.mapOptions.center = { lon: 33.44, lat: 14.45 };
      }

      clone.mapOptions.zoom = zoomScale(size.height || 420);
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'capitals.csv') layer.visible = 1;
        if (layer.name === 'SDN_ADM1.geojson') layer.showLabels = true;
        if (layer.name === 'Aj Jazirah State') layer.visible = 1;
        if (layer.name === 'SDN_ADM2.geojson') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2_centroids') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2_centroids') layer.enableTooltips = true;
        if (layer.name === 'Refugees') layer.zIndex = 18;
        if (layer.name === 'Greater Darfur') layer.visible = 0;
        if (layer.name === 'Khartoum state') layer.visible = 0;
        if (layer.name === 'Greater Kordofan') layer.visible = 0;
        if (layer.name === 'State capitals') layer.visible = 1;
        if (layer.name === 'Shaded mask') layer.mask = 'POLYGON((0 90,180 90,180 -90,0 -90,-180 -90,-180 0,-180 90,0 90),(31.32622352923705 14.547034201272226,31.493034213710878 15.383860436189465,32.2706080035337 16.045992398005595,33.90464438026002 16.123181501579836,34.87284016398717 15.728066976218486,35.21408097946936 15.17645746482934,35.180964199964414 14.23059335505657,35.06758810040751 13.6079438233253,34.14554190009183 12.948425240027134,33.497740637775905 12.74682509996137,31.965129429044246 12.920285810301266,31.406576277703408 13.740031964318305,31.32622352923705 14.547034201272226))';
        if (layer.name === 'Country labels') {
          layer.data = [{
            lat: 9,
            lon: 29.2,
            name_en: 'South Sudan',
            population: 1410858,
          }];
        }
      });
    } else {
      // COUNTRY VIEW
      adm1ListNew = [...adm1Array];
      adm2ListNew = [...adm2Array];
      foodSecurityAdm1New = foodSecurityAdm1CentroidFeatures;
      adm2IPCFilteredFeatures = adm2Features;
      if (isMobile) {
        zoomScale = d3.scaleLog().domain([500, 4000]).range([4.0, 8.6]);
        clone.mapOptions.center = { lon: 29.9, lat: 16.49 };
      } else {
        zoomScale = d3.scaleLog().domain([530, 4000]).range([4.9, 8.6]);
        clone.mapOptions.center = { lon: 31.5, lat: 16.47 };
      }
      clone.mapOptions.zoom = zoomScale(size.height || 420);
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'capitals.csv') layer.visible = 1;
        if (layer.name === 'SDN_ADM1.geojson') layer.showLabels = true;
        if (layer.name === 'SDN_ADM2.geojson') layer.showLabels = false;
        if (layer.name === 'Khartoum state') layer.visible = 0;
        if (layer.name === 'Greater Kordofan') layer.visible = 0;
        if (layer.name === 'SDN_ADM2_centroids') layer.showLabels = false;
        if (layer.name === 'SDN_ADM2_centroids') layer.enableTooltips = true;
        if (layer.name === 'Greater Darfur') layer.visible = 0;
        if (layer.name === 'Khartoum state') layer.visible = 0;
        if (layer.name === 'State capitals') layer.visible = 1;
        if (layer.name === 'Refugees') layer.zIndex = 23;
        if (layer.name === 'Shaded mask') layer.mask = 'POLYGON((0 90,180 90,180 -90,0 -90,-180 -90,-180 0,-180 90,0 90),(21.893756216246157 21.291128381499988,25.042803365525856 23.208409696277272,33.10440785620573 23.267792738410122,36.02205782260023 23.918369412587296,37.77477196802118 22.638144453281228,38.32679202268262 20.423941713803288,39.6644364362147 18.606867554433464,39.658943045730986 16.95185710741319,38.004481704869676 15.830770955299002,37.808095731718964 13.602991580239774,36.88829655352942 11.433305226805828,36.484999619402004 9.911642356924503,35.59057521789716 8.524045982915396,34.1517087869711 8.163805611563404,32.305310724884386 7.925365672571331,29.801837572388244 7.5684548906191225,27.03878695345683 7.643231639867494,25.481305391039903 6.910647519946096,21.65042049251307 6.821770835287907,19.30153979952281 8.567652247484887,18.645293819855844 13.751574674362331,21.21477613201174 18.24295524745372,21.893756216246157 21.291128381499988))';
        if (layer.name === 'Country labels') {
          layer.data = [
            {
              lat: 9.07,
              lon: 21.0,
              name_en: 'Central African Rep.',
              population: 1410858,
            },
            {
              lat: 15.8,
              lon: 20.5,
              name_en: 'Chad',
              population: 1410858,
            },
            {
              lat: 23.3,
              lon: 30.1,
              name_en: 'Egypt',
              population: 1410858,
            },
            {
              lat: 10.5,
              lon: 37.5,
              name_en: 'Ethiopia',
              population: 1410858,
            },
            {
              lat: 21.3,
              lon: 23,
              name_en: 'Libya',
              population: 1410858,
            },
            {
              lat: 8,
              lon: 28.9,
              name_en: 'South Sudan',
              population: 1410858,
            },
            {
              lat: 15.95,
              lon: 37.8,
              name_en: 'Eritrea',
              population: 1410858,
            }];
        }
      });
    }
    // FOOD SECURITY TAB
    if (adm1Filter.length > 0) {
      // eslint-disable-next-line max-len
      foodSecurityAdm1New = foodSecurityAdm1New.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE));
      adm2IPCFilteredFeatures = adm2IPCFilteredFeatures.filter((d) => adm1Filter.includes(d.properties.ADM1_PCODE)); // eslint-disable-line
    }
    if (adm2Filter.length > 0) {
      // eslint-disable-next-line max-len
      adm2IPCFilteredFeatures = adm2IPCFilteredFeatures.filter((d) => adm2Filter.includes(d.properties.ADM2_PCODE)); // eslint-disable-line
    }
    
    const totalPop = d3.sum(adm2IPCFilteredFeatures, (d) => d.properties.estimated_population);
    setTotalPopulation(totalPop);
    const totalP3plus = d3.sum(adm2IPCFilteredFeatures, (d) => (d.properties.p3plus));
    setTotalP3Plus(totalP3plus);
    const averageIPC = d3.mean(adm2IPCFilteredFeatures, (d) => (d.properties.overall_phase));
    setAverageIPC(averageIPC);

    // after filtering for adm1/2 + subview
    setAdm2List(adm2ListNew);
    setAdm1List(adm1ListNew);

    if (tabIndex === 1){
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM2_centroids.geojson') {
          layer.data.features = adm2IPCFilteredFeatures;
          layer.style.ts = Math.random();
          layer.visible = 1;
          layer.style.fillScaleType = 'steps';
          layer.showInLegend = false;
          layer.opacity = 0.9;          
        }
        if (layer.name === 'SDN_ADM2.geojson') {
          layer.style.ts = Math.random();
          layer.visible = 1;
          layer.style.fillScaleType = 'steps';
          layer.showInLegend = false;
          layer.enableTooltips = false;
          layer.opacity = 0.9;
          layer.hoverFn = null;
        }
      });
    }
    if (tabIndex === 2){
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM2.geojson') {
          layer.data.features = adm2IPCFilteredFeatures;
          layer.style.ts = Math.random();
          layer.visible = 1;
          layer.style.fillScaleType = 'steps';
          layer.showInLegend = false;
          layer.enableTooltips = false;
          layer.opacity = 0.9;
        }
      });
    }
     else if (tabIndex === 3){
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'SDN_ADM2.geojson') {
          layer.data.features = adm2IPCFilteredFeatures;
          layer.style.ts = Math.random();
          layer.visible = 1;
          const max = d3.max(adm2IPCFilteredFeatures, d => d.properties.prot_hotspot_count);
          layer.style.fillDataMax = max;
          layer.style.fillScaleType = 'continuous';
          layer.style.fillDataMin = 0;
          layer.legendSeriesTitle = 'Protection hotspots';
          layer.opacity = 0.9;
          layer.showInLegend = true;
          layer.enableTooltips = true;
          layer.tooltipsTitleColumn = 'ADM2_EN';
          layer.tooltipsValueColumn = 'prot_hotspot_count';
          layer.tooltipsValueLabel = 'protection hotspots';
          layer.primaryColor = '#295fa0';
        }
      });
    } else {
      clone.layers.forEach((l) => {
        const layer = l;
        if (layer.name === 'IPC_ADM1_centroids') layer.visible = 0;
        if (layer.name === 'SDN_ADM2.geojson') {
          layer.opacity = 1;
          layer.enableTooltips = false;
          layer.showInLegend = false;
          layer.data.features = adm2IPCFilteredFeatures;
        }
      });
    }

    filterIDPData(!ignoreFilter);
    filterAcledData(!ignoreFilter);

    if (tabIndex === 0) {
      setMapConfig(clone);
      setMainView('');

    }
    if (tabIndex === 1) {
      setMapConfig(clone);
      setMainView('displacement');
    }
    if (tabIndex === 2) {
      setMapConfig(clone);
      setMainView('food-security');
    }

    if (tabIndex === 3) {
      setMapConfig(clone);
      setMainView('protection');
    }

    if (tabIndex === 10) {
      setMapConfig(clone);
      setMainView('grid');
    }

    if (tabIndex === 11) {
      setMapConfig(clone);
      setMainView('documents');
    }
  };

  useEffect(() => {
    if (size.width < 880) {
      setIsMobile(true) 
    } else {
      setIsMobile(false)
    }
    if (window.innerWidth < window.innerHeight) {
      return;
    }
    changeView(true);
  }, [size]);

  const handleConflictToggle = (a) => {
    const newConfig = { ...mapConfig };
    newConfig.layers.forEach((l) => {
      const layer = l;
      if (a === 'heatmap') {
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.type = 'heatmap';
        if (layer.name === 'ACLED EVENTS') layer.radius = radius;
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.visible = 1;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        if (subViewArr[subView] === 'khartoum' && layer.name === 'SDN_ADM2.geojson') layer.showLabels = true;
        if (subViewArr[subView] === 'khartoum' && layer.name === 'SDN_ADM2.geojson') layer.visible = 1;
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.radius = radius;    
      } else if (a === 'hexbin') {
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.type = 'hexbin';
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.visible = 1;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.radius = hexRadius;  
        if (layer.name === 'SDN_ADM2.geojson') layer.showLabels = false;
        if (subViewArr[subView] === 'khartoum' && layer.name === 'SDN_ADM2.geojson') layer.showLabels = true;
        if (subViewArr[subView] === 'khartoum' && layer.name === 'SDN_ADM2.geojson') layer.visible = 1;
      } else if (a === 'adm2') {
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.visible = 0;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 0;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 1;
        if (layer.name === 'SDN_ADM2.geojson') layer.showLabels = false;
      } else if (a === 'adm1') {
        // eslint-disable-next-line
        if (layer.name === 'ACLED EVENTS') layer.visible = 0;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM1_centroids') layer.visible = 1;
        // eslint-disable-next-line
        if (layer.name === 'SDN_ADM2_centroids') layer.visible = 0;
        if (layer.name === 'SDN_ADM2.geojson' && subViewArr[subView] === 'khartoum') layer.showLabels = true;
      }
    });
    filterAcledData();
    if (tabIndex === 0) setMapConfig(newConfig);
  };

  useEffect(() => {
    changeView(false);
  }, [subView]);

  useEffect(() => {
    changeView(true);
  }, [tabIndex]);

  useEffect(() => {
    handleConflictToggle(conflictToggle);
  }, [conflictToggle]);

  useEffect(() => {
    filterAcledData();
  }, [weighted, dateRange, acledEventType, acledActorToggle]);

  useEffect(() => {
    changeView(true);
  }, [adm1Filter, adm2Filter]);

  useEffect(() => {
    document.title = 'iMMAP Sudan Dashboard 2023';
    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link.setAttribute('href', 'favicon-immap.png');
    }
    changeView();
    handleAdmToggle();
  }, []);

  const monthPicker = monthObj.map((m) => (
    <div
      style={{ width: `${(100 / (monthObj.length) / 100) * 100}%` }}
      key={m.date}
    >
      <button onClick={clickMonthHandler} data-date={m.date} type="button">{m.label}</button>
    </div>
  ));

  const theme = createTheme({
    palette: {
      primary: {
        main: '#be2126',
        dark: '#be2126',
        light: '#be2126',
        contrastText: '#be2126',
      },
      secondary: {
        main: 'rgb(65, 143, 222)',
        dark: 'rgb(65, 143, 222)',
        light: 'rgb(65, 143, 222)',
        contrastText: 'rgb(65, 143, 222)',
      },
    },
  });

  const rootStyle = {};
  const subViewFilterStyle = {};
  let mainTabsStyle = {};
  let gridTabStyle = {'visibility': 'hidden'};
  let documentsTabStyle = {'display': 'none'};
  if (tabIndex === 10) {
    rootStyle.height = '100vh';
    rootStyle.overflowY = 'scroll';
    subViewFilterStyle.position = 'absolute';
    subViewFilterStyle.top = -73;
    if (isMobile) subViewFilterStyle.top = -98;
    subViewFilterStyle.left = 0;
    subViewFilterStyle.width = '100%';    
    subViewFilterStyle.zIndex = 9999; 
    gridTabStyle = {};
    documentsTabStyle = {'display': 'none'};
    mainTabsStyle = {'visibility': 'hidden'};   
  }

  if (tabIndex === 11) {
    rootStyle.height = '100vh';
    rootStyle.overflowY = 'scroll';
    subViewFilterStyle.visibility = 'hidden';
    documentsTabStyle = {};
    mainTabsStyle = {};   
    gridTabStyle = {'visibility': 'hidden'};   
  }

  return (
    <div className={styles.root} style={rootStyle}>
      <div className={styles.body}>

        {/* HEADER */}
        <div className={styles.header}>
          <SudanNav onChange={clickTab} tabIndex={tabIndex} subView={subView} subViewArr={subViewArr} />
        </div>
        {tabIndex < 10 && !isMobile && (
        <Link className={styles.embedIframe} to="./iframe">
          <img width="19px" src={embedIcon} alt="" />
        </Link>
        )}
        {tabIndex < 10 && !isMobile && (
          <div className={styles.exportButton} style={{}}>
            <Button id={printPNGId} size="small" theme={theme} variant="outlined" onClick={printPNG}>Export to PNG</Button>
          </div>
        )}
        { dev && (
        <div className={styles.saveButton}>
          <Button
            size="small"
            theme={theme}
            variant="outlined"
            onClick={() => {
              const blob = new Blob([JSON.stringify(mapConfig)], { type: 'text/plain;charset=utf-8' });
              saveFile(blob, 'SUDAN_conflict_export.json');
            }}
          >
            Save
          </Button>
        </div>
        )}


        {/* APP BODY */}
        <div className={styles.App}>

          {/* KPIs (tab 0,1,2,3) */}
          {tabIndex < 10 && (
          <div className={styles.kpi}>
            <KPIs data={kpiData} leftBorder isMobile={isMobile} />
          </div>
          )}

          {/* MapVizzard Tabs 0123 */}
          <div className={`sudanDashboard ${styles.dashboard}`} style={mainTabsStyle}>
            {mapvizzard}
          </div>

          {/* GRID Map Tab 10 */}
          <div className={`sudanDashboard ${styles.map}`} style={gridTabStyle}>
            {mapgrid}
          </div>

          {/* DOCUMENTS Map Tab 11 */}
          <div className={`sudanDashboard ${styles.map}`} style={documentsTabStyle}>
            <div className={`documents ${styles.documents}`}><Documents /></div>
          </div>

          {/* FILTER CONTROLS ROW */}
          <div style={subViewFilterStyle}>
            {/* SUBVIEW Tabs */}
            <div className={styles.subViews}>
              <div className={styles.bTitle}>SUB-VIEWS</div>
              <ArkTabs.Root
                value={subView}
                onValueChange={({ value }) => {
                  handleSubViewChange(1, value);
                  // navigate(`/${value === 'home' ? '' : value}`)
                }}
              >
                <ArkTabs.List>
                  <ArkTabs.Trigger value={0}><Link to={`${mainView}/`}>Country</Link></ArkTabs.Trigger>
                  <ArkTabs.Trigger value={1}><Link to={`${mainView}/ajjazirah`}>Aj Jazirah</Link></ArkTabs.Trigger>
                  <ArkTabs.Trigger value={2}><Link to={`${mainView}/darfur`}>Greater Darfur</Link></ArkTabs.Trigger>
                  <ArkTabs.Trigger value={3}><Link to={`${mainView}/kordofan`}>Greater Kordofan</Link></ArkTabs.Trigger>
                  <ArkTabs.Trigger value={4}><Link to={`${mainView}/khartoum`}>Khartoum</Link></ArkTabs.Trigger>
                </ArkTabs.List>
              </ArkTabs.Root>
            </div>
            <div className={styles.admSelectContainer}>
              {/* ADM1 Filter */}
              <div className={`${styles.admSelect} ${styles.adm1Select}`}>
                <FormControl sx={{ m: 1, width: '150px', zoom: 1.03 }} fullWidth size="small">
                  <Select
                    labelId="adm1-multiselect-label"
                    id="adm1-multiselect"
                    multiple
                    displayEmpty
                    size="small"
                    SelectDisplayProps={{
                      style: {
                        paddingTop: 5.4,
                        paddingBottom: 5.4,
                        paddingLeft: 9,
                        paddingRight: 20,
                      },
                    }}
                    value={adm1Filter}
                    renderValue={(value) => {
                      if (value.length > 0) {
                        const selected = [];
                        value.forEach((d) => {
                          const adm1 = adm1List.filter((a) => a.pcode === d);
                          selected.push(adm1[0].name);
                        });
                        return <b>{selected.join(', ')}</b>;
                      }
                      return <span>Filter by State</span>;
                    }}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      const newValue = typeof value === 'string' ? value.split(',') : value;
                      setAdm1Filter(newValue);
                      return true;
                    }}
                    theme={theme}
                    input={<OutlinedInput id="select-multiple-chip" label="State filter" />}
                    MenuProps={Adm1MenuProps}
                  >
                    {adm1List.map((d) => (
                      <MenuItem
                        key={d.name}
                        value={d.pcode}
                        style={{ fontFamily: 'Barlow Condensed' }}
                        size="small"
                      >
                        <Checkbox size="small" style={{ marginLeft: 2, paddingRight: 0 }} theme={theme} checked={adm1Filter.indexOf(d.pcode) > -1} />
                        <ListItemText
                          primary={d.name}
                          secondary={d.pcode}
                          secondaryTypographyProps={{ style: { fontSize: 8 } }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* ADM2 Filter */}
              <div className={`${styles.admSelect} ${styles.adm2Select}`}>
                <FormControl sx={{ m: 1, width: '150px', zoom: 1.03 }} fullWidth size="small">
                  <Select
                    labelId="adm2-multiselect-label"
                    id="adm2-multiselect"
                    multiple
                    displayEmpty
                    size="small"
                    SelectDisplayProps={{
                      style: {
                        paddingTop: 5.4,
                        paddingBottom: 5.4,
                        paddingLeft: 9,
                        paddingRight: 20,
                      },
                    }}
                    value={adm2Filter}
                    renderValue={(value) => {
                      if (value.length > 0) {
                        const selected = [];
                        value.forEach((d) => {
                          const adm2 = adm2List.filter((a) => a.pcode === d);
                          if (adm2.length > 0) selected.push(adm2[0].name);
                        });
                        return <b>{selected.join(', ')}</b>;
                      }
                      return <span>Filter by Locality</span>;
                    }}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      const newValue = typeof value === 'string' ? value.split(',') : value;
                      setAdm2Filter(newValue);
                      return true;
                    }}
                    theme={theme}
                    input={<OutlinedInput id="select-multiple-chip" label="Locality filter" />}
                    MenuProps={Adm1MenuProps}
                  >
                    {adm2List.map((d) => (
                      <MenuItem
                        key={d.pcode}
                        value={d.pcode}
                        style={{ fontFamily: 'Barlow Condensed' }}
                        size="small"
                      >
                        <Checkbox size="small" style={{ marginLeft: 2, paddingRight: 0 }} theme={theme} checked={adm2Filter.indexOf(d.pcode) > -1} />
                        <ListItemText
                          primary={d.name}
                          secondary={d.pcode}
                          secondaryTypographyProps={{ style: { fontSize: 8 } }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* GRID Controls */}
              {tabIndex === 10 && (
                <div style={{display: 'inline-block'}}>
                  {/* GRID control - Select which MAPS to display (using KPI array) */}
                  <div className={`${styles.admSelect} ${styles.gridSelect}`}>
                    <div style={{ paddingTop: 5, display: 'inline', position: 'relative', top: 5}}><img width="15px" style={{ opacity: 0.1, marginRight: 5 }} src={gridIcon} alt="" /></div>
                    <FormControl sx={{ m: 1, width: '220px', zoom: 1.03 }} fullWidth size="small">
                      <Select
                        labelId="grid-multiselect-label"
                        id="grid-multiselect"
                        multiple
                        displayEmpty
                        size="small"
                        SelectDisplayProps={{
                          style: {
                            paddingTop: 5.4,
                            paddingBottom: 5.4,
                            paddingLeft: 9,
                            paddingRight: 20,
                          },
                        }}
                        value={gridSort}
                        renderValue={(value) => {
                          if (value.length > 0) {
                            const selected = [];
                            value.forEach((d) => {
                              const grid = gridKPIdata.kpis.filter((a) => a.id === d);
                              if (grid.length > 0) selected.push(grid[0].title);
                            });
                            return <b>{selected.join(', ')}</b>;
                          }
                          return <span>Select maps to display</span>;
                        }}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;
                          const newValue = typeof value === 'string' ? value.split(',') : value;
                          setGridSort(newValue);
                          return true;
                        }}
                        theme={theme}
                        input={<OutlinedInput id="select-multiple-chip" label="Grid maps" />}
                        MenuProps={Adm1MenuProps}
                      >
                        {gridKPIdata.kpis.map((d) => (
                          <MenuItem
                            key={d.id}
                            value={d.id}
                            style={{ fontFamily: 'Barlow Condensed' }}
                            size="small"
                          >
                            <Checkbox size="small" style={{ marginLeft: 2, paddingRight: 0 }} theme={theme} checked={gridSort.indexOf(d.id) > -1} />
                            <ListItemText
                              primary={d.title}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles.gridColsSelectTitle} style={{ paddingTop: 5, display: "inline-block", paddingLeft: 5, fontSize: 10, color: 'grey'}}>COLS</div>
                  {/* GRID control - Number of Columns */}
                  <div className={`${styles.admSelect} ${styles.gridColsSelect}`}>
                  <FormControl sx={{ m: 1, width: '80px', zoom: 1.03 }} fullWidth size="small">
                    <Select
                      labelId="grid-cols-label"
                      id="grid-cols-select"
                      size="small"
                      SelectDisplayProps={{
                        style: {
                          paddingTop: 5.4,
                          paddingBottom: 5.4,
                          paddingLeft: 9,
                          paddingRight: 10,
                        },
                      }}
                      value={numCols}
                      onChange={(e, value) => {
                        return setNumCols(value.props.value);
                      }}
                      theme={theme}
                      input={<OutlinedInput id="select-multiple-chip" label="Grid map columns" />}
                      MenuProps={GridColsMenuProps}
                    >
                        <MenuItem style={{ fontFamily: 'Barlow Condensed' }}
                            size="small" value="auto">auto</MenuItem>
                        <MenuItem value="1" style={{ fontFamily: 'Barlow Condensed' }}
                            size="small">1</MenuItem>
                        <MenuItem value="2" style={{ fontFamily: 'Barlow Condensed' }}
                            size="small">2</MenuItem>
                        <MenuItem value="3" style={{ fontFamily: 'Barlow Condensed' }}
                            size="small">3</MenuItem>
                        <MenuItem value="4" style={{ fontFamily: 'Barlow Condensed' }}
                            size="small">4</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              )}
            </div>
          </div>
          

          {/* ACLED MAP CONTROLS [0] */}
          {tabIndex === 0 && (
          <div className={styles.acledControls}>

            {/* ACLED date range slider */}
            <div className={styles.acledDateRangeSlider}>
              <div className={styles.acledDateRangeSliderInner}>
                <div className={styles.selectedDateRangeTitle}>Selected Date Range</div>
                <div className={styles.selectedDateRange}>{dateRangeStr}</div>
                <div className={styles.barSpark}>
                  {barSpark}
                </div>
                <div style={{ marginRight: sliderMarginRight, marginLeft: 2, height: 18 }}>
                  <Slider
                    getAriaLabel={() => 'Date range'}
                    value={dateRange}
                    size="small"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={handleDateChange}
                    valueLabelDisplay="off"
                    min={0}
                    step={1}
                    max={daysArray.length - 1}
                    theme={theme}
                    style={{ marginTop: -17, marginRight: 10 }}
                  />
                </div>
                <div
                  className={styles.monthPicker}
                >
                  {monthPicker}
                </div>
              </div>
            </div>

            {/* ACLED map type select (hexbin, heatmap, adm1, adm2) */}
            <div className={styles.acledMapType}>
              <div className={styles.conflictToggleTitle}>MODE</div>
              <div className={styles.conflictToggle}>
                <ToggleButtonGroup
                  value={conflictToggle}
                  color="primary"
                  exclusive
                  // orientation="vertical"
                  theme={theme}
                  size="small"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setConflictToggle(newValue);
                    }
                  }}
                  aria-label="Filter"
                >
                  <ToggleButton theme={theme} value="heatmap" style={{ justifyContent: 'flex-start' }}>
                    <img width="15px" src={heatmapIcon} alt="" />
                    Heatmap
                  </ToggleButton>
                  <ToggleButton theme={theme} value="hexbin" style={{ justifyContent: 'flex-start' }}>
                    <img width="15px" src={hexbinIcon} alt="" />
                    Hexbin
                  </ToggleButton>
                  <ToggleButton theme={theme} value="adm1" style={{ justifyContent: 'flex-start' }}>
                    <img width="15px" src={adm1Icon} alt="" />
                    State
                  </ToggleButton>
                  <ToggleButton theme={theme} value="adm2" style={{ justifyContent: 'flex-start' }}>
                    <img width="15px" src={adm2Icon} alt="" />
                    Locality
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

            {/* ACLED actors select */}
              <div className={styles.acledActorSelect}>
                <FormControl sx={{ m: 1, width: '100%' }} fullWidth size="small">
                  <Select
                    labelId="actor-multiselect-label"
                    id="actor-multiselect"
                    multiple
                    fullWidth
                    displayEmpty
                    size="small"
                    style={{ width: '100%' }}
                    value={acledActorToggle}
                    renderValue={(value) => {
                      if (value.length > 0) {
                        const selected = [];
                        value.forEach((d) => {
                          const actor = actors.filter((a) => a.code === d);
                          selected.push(actor[0].name);
                        });
                        return selected.join(', ');
                      }
                      return <span>ACTORS</span>;
                    }}
                    // renderValue={(selected) => selected.join(', ')}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      const newValue = typeof value === 'string' ? value.split(',') : value;
                      if (newValue.length > 2) return false;
                      setAcledEventType(newValue);
                      return true;
                    }}
                    theme={theme}
                    input={<OutlinedInput id="select-multiple-chip" label="Actors" />}
                    MenuProps={MenuProps}
                  >
                    {actors.map((d) => (
                      <MenuItem
                        key={d.name}
                        value={d.code}
                        style={{ fontFamily: 'Barlow Condensed' }}
                        size="small"
                      >
                        {/* <MenuItem key={name} value={name}> */}
                        <Checkbox size="small" theme={theme} checked={acledActorToggle.indexOf(d.code) > -1} />
                        <ListItemText primary={d.name} />
                        {/* </MenuItem> */}
                        {/* {d.name} */}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            {/* ACLED weighted checkbox */}
            <div className={styles.acledWeighted}>
              <div className={styles.weightedCheck}>
                <Checkbox
                  checked={weighted}
                  size="small"
                  theme={theme}
                  onChange={(e, val) => handleWeighted('weighted', val)}
                />
                Weight by fatalities
              </div>
            </div>
            <br />

            {/* ACLED event type filter (explosions, battles...) */}
            <div className={styles.acledEventType}>
              <div className={styles.eventTypeTitle}>Event Type</div>
              <ToggleButtonGroup
                value={acledEventType}
                color="primary"
                exclusive
                orientation={!isMobile ? "vertical" : "horizontal"}
                theme={theme}
                size="small"
                onChange={(event, newValue) => {
                  setAcledToggle(newValue);
                }}
                aria-label="Filter"
              >
                <ToggleButton theme={theme} value="explosions" style={{ justifyContent: 'flex-start' }}>
                  <img width="15px" src={airIcon} alt="" />
                  Explosions/Remote violence
                </ToggleButton>
                <ToggleButton theme={theme} value="battles" style={{ justifyContent: 'flex-start' }}>
                  <img width="15px" src={attackIcon} alt="" />
                  Battles
                </ToggleButton>
                <ToggleButton theme={theme} value="violence" style={{ justifyContent: 'flex-start' }}>
                  <img width="15px" src={violenceIcon} alt="" />
                  Violence against civilians
                </ToggleButton>
                <ToggleButton theme={theme} value="looting" style={{ justifyContent: 'flex-start', display: 'none' }}>
                  <img width="15px" src={propertyIcon} alt="" />
                  Looting/property destruction
                </ToggleButton>
                {/* <ToggleButton value="other">Other</ToggleButton> */}
              </ToggleButtonGroup>
            </div>

            {/* ACLED actors filter (State Armed Forces, Militia...) */}
            <div className={styles.acledActorToggle}>
              <div className={styles.actorTitle}>Actors</div>
              <ToggleButtonGroup
                value={acledActorToggle}
                color="primary"
                orientation="vertical"
                theme={theme}
                size="small"
                onChange={(event, newValue) => {
                  if (newValue.length > 2) return false;
                  setAcledEventType(newValue);
                  return true;
                }}
                aria-label="Filter"
              >
                <ToggleButton className={styles.actorButton} theme={theme} value="is_sudan_state" style={{ justifyContent: 'flex-start' }}>
                  State Armed Forces, Sudan
                </ToggleButton>
                <ToggleButton className={styles.actorButton} theme={theme} value="is_rsf" style={{ justifyContent: 'flex-start' }}>
                  Rapid Support Forces
                </ToggleButton>
                <ToggleButton className={styles.actorButton} theme={theme} value="is_nsag" style={{ justifyContent: 'flex-start' }}>
                  Non-state Armed Group
                </ToggleButton>
                <ToggleButton className={styles.actorButton} theme={theme} value="is_militia" style={{ justifyContent: 'flex-start' }}>
                  Militia
                </ToggleButton>
                <ToggleButton className={styles.actorButton} theme={theme} value="are_civilians" style={{ justifyContent: 'flex-start' }}>
                  Civilians
                </ToggleButton>
                <ToggleButton className={styles.actorButton} theme={theme} value="are_aid_actors" style={{ justifyContent: 'flex-start' }}>
                  Aid actor(s)
                </ToggleButton>
                {/* <ToggleButton value="other">Other</ToggleButton> */}
              </ToggleButtonGroup>
            </div>

            {/* ACLED heatmap radius slider */}
            {conflictToggle === 'heatmap' && (
            <div className={styles.dashboardRadius}>
              <div className={styles.dashboardRadiusInner}>
                <div className={styles.dashboardRadiusTitle}>Heatmap Radius</div>
                <Slider
                  getAriaLabel={() => 'Radius'}
                  value={radius}
                  size="small"
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleRadiusChange}
                  valueLabelDisplay="off"
                  min={0.2}
                  step={0.4}
                  max={8}
                  marks
                  theme={theme}
                />
              </div>
            </div>
            )}

            {/* ACLED hexbin radius slider */}
            {conflictToggle === 'hexbin' && (
            <div className={styles.dashboardRadius}>
              <div className={styles.dashboardRadiusInner}>
                <div className={styles.dashboardRadiusTitle}>Hexbin Radius</div>
                <Slider
                  getAriaLabel={() => 'Radius'}
                  value={hexRadius}
                  size="small"
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={handleHexRadiusChange}
                  valueLabelDisplay="off"
                  min={0.2}
                  step={0.4}
                  marks
                  max={8}
                  theme={theme}
                />
              </div>
            </div>
            )}

          </div>
          )} {/* end ACLED controls */}

          {/* DISPLACEMENT Map Controls [2] */}
          {tabIndex === 1 && (
          <div className={styles.displacementControls}>
            <div className={styles.dtmSvg}>
              <div className={styles.dtmSvgTitle}>IDPs by date</div>
              <LineChart height={80} width={'100%'} yAxisPosition="right" yAxisIntervals={2} fontColor="grey" data={idpsByDate} color={'#418FDE'} valueLabel="IDPs" showTooltips />
            </div>
            <div className={styles.displacementControlsUpper}>
              {/* Show IDPs by */}
              {/* <br /> */}
              <div className={styles.displacementToggle}>
              <div className={styles.displacementToggleTitle}>MODE</div>
                <ToggleButtonGroup
                  value={admToggle}
                  color="secondary"
                  exclusive
                  // orientation="vertical"
                  theme={theme}
                  size="small"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setAdmToggle(newValue);
                    }
                  }}
                  aria-label="Filter"
                >
                  <ToggleButton theme={theme} value="adm1" style={{ justifyContent: 'flex-start' }}>
                    <img width="15px" src={adm1BlueIcon} alt="" />
                    State
                  </ToggleButton>
                  <ToggleButton theme={theme} value="adm2" style={{ justifyContent: 'flex-start' }}>
                    <img width="15px" src={adm2BlueIcon} alt="" />
                    Locality
                  </ToggleButton>
                  <ToggleButton theme={theme} value="schools" style={{ justifyContent: 'flex-start' }}>
                    {/* <img width="15px" src={adm2BlueIcon} alt="" /> */}
                    <span style={{color: '#64ac9a'}}>Occupied Schools</span>
                    <div style={{backgroundColor: '#64ac9a', borderRadius: 10, marginLeft: 4, color: 'white', padding: '1px 5px 1px 5px', display: 'inline-block'}}>{totalOccupiedSchools}</div>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </div>

          )} {/* end displacmenet controls */}


          {/* FOOD SECURITY TAB Controls [2] */}
          <div>
            {tabIndex === 2 && (
              <div className={styles.foodSecurityMainTitle}>
                Acute Food Insecurity Projection Update for October 2023 - February 2024 (IPC)
              </div>
            )}
            {tabIndex === 2 && (
              <div className={styles.foodSecurityTotals}>
                {foodSecurityTable}
              </div>
            )}
          </div>

          {/* PROTECTION TAB Controls [3] */}
          <div>
            {tabIndex === 3 && (
              <div className={styles.foodSecurityMainTitle}>
                {/* Acute Food Insecurity Projection Update for October 2023 - February 2024 (IPC) */}
              </div>
            )}
            {tabIndex === 3 && (
              <div className={styles.foodSecurityTotals}>
                {protectionTable}
              </div>
            )}
          </div>
          
          {/* ACLED - excluded from Heatmap precision disclaimer */}
          {tabIndex === 0 && (conflictToggle === 'hexbin' || conflictToggle === 'heatmap') && ((weighted === false && totalExcludedFromHeatMap > 0) || (weighted === true && totalFatalitiesExcludedFromHeatmap > 0)) && (
          <div className={styles.excludedFromHeatmap}>
            <span>
              { weighted ? totalFatalitiesExcludedFromHeatmap : totalExcludedFromHeatMap }
            </span>
            &nbsp;
            { weighted ? 'fatalities ' : 'incidents '}
            are excluded from the
            { conflictToggle === 'hexbin' && ' hexbin '}
            { conflictToggle === 'heatmap' && ' heatmap '}
            due to low geo precision
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SudanDashboard;
