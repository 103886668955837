import { useEffect, useMemo, useState } from 'react';
import OLVectorLayer from 'ol/layer/Vector';
import {
  Style,
  Stroke,
  Icon,
  Fill,
  Text,
} from 'ol/style';
import Overlay from 'ol/Overlay';
import Circle from 'ol/style/Circle';
import * as d3 from 'd3';
import * as d3ColorScale from 'd3-scale-chromatic';
import { unByKey } from 'ol/Observable';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
// import { rgba } from '../../MapVizzard/MapOptions/ColorPicker';
import { numberFormatter, convertRGBtoRGBA } from '../Helpers';
import { vector } from '../Source';
import { rgba } from '../../MapVizzard/MapOptions/ColorPicker';
import capital from '../assets/map-icons/capital.svg';
import city from '../assets/map-icons/city.svg';
import settlement from '../assets/map-icons/settlement.svg';
import marker from '../assets/map-icons/marker.svg';
import airport from '../assets/map-icons/airport.svg';
import borderCrossing from '../assets/map-icons/borderCrossing.svg';
import borderCrossingActive from '../assets/map-icons/borderCrossingActive.svg';
import borderCrossingPotential from '../assets/map-icons/borderCrossingPotential.svg';
import triangle from '../assets/map-icons/triangle.svg';
import idpRefugeeCamp from '../assets/map-icons/idp-refugee-camp.svg';
import down from '../../MapVizzard/assets/down.svg';
import up from '../../MapVizzard/assets/up.svg';

function SymbolLayer({
  map,
  source,
  layerId,
  data,
  symbol,
  zIndex = 1,
  opacity = 1,
  showLabels = false,
  labelColumn = '',
  labelStyle = '',
  scale = 1,
  scaleType = 'fixed',
  scaleScaling = 'flannery',
  scaleColumn = '',
  scaleDataMin = 0,
  scaleDataMax = 0,
  style,
  enableTooltips = false,
  tooltipsTitleColumn = '',
  tooltipsValueColumn = '',
  tooltipsValueLabel = '',
  tooltipsValueColumnSecondary = '',
  tooltipsValueLabelSecondary = '',
  primaryColor,
}) {
  const [symbolLayer, setSymbolLayer] = useState(undefined);
  const [tooltipListener, setTooltipListener] = useState(undefined);

  const symbolIcons = {
    capital,
    city,
    settlement,
    'idp-refugee-camp': idpRefugeeCamp,
    airport,
    marker,
    borderCrossing,
    borderCrossingActive,
    borderCrossingPotential,
    triangle,
    circle: 'circle',
  };

  const colorsArray = useMemo(() => {
    if (!style.fillPalette) return [];
    let colors;
    if ((style.fillType === 'graduated') || (style.fillType === 'continuous')) {
      const interpolator = d3ColorScale[`interpolate${style.fillPalette}`];
      let numSteps = style.fillSteps;
      if (style.fillScaleType === 'continuous') numSteps = 50;
      // eslint-disable-next-line
      colors = Array.from({ length: numSteps }, (_, i) => interpolator(i * (1 / numSteps)));
    } else if (style.fillType === 'categorical') {
      colors = {};
      let i = 0;
      let palette = d3ColorScale[`scheme${style.fillPaletteCategorised}`];
      if ((style.fillScaleInvert) && (typeof palette !== 'undefined')) {
        palette = [...palette].reverse();
      }
      source.forEachFeature((d) => {
        // eslint-disable-next-line
        const index = d['values_'][style.fillColumn];
        if (typeof palette !== 'undefined') {
          colors[index] = palette[i];
          i += 1;
          if (i === palette.length) i = 0;
        }
      });
    } else {
      colors = d3ColorScale[`scheme${style.fillPalette}`];
    }
    if ((style.fillScaleInvert) && (style.fillType !== 'categorical')) colors.reverse();
    return colors;
  }, [
    source,
    style.fillPalette,
    style.fillPaletteCategorised,
    style.fillScaleInvert,
    style.fillType,
    style.fillSteps,
    style.fillScaleType,
    style.fillColumn,
  ]);

  const colorsArrayNegative = useMemo(() => {
    if (!style.fillPalette) return [];
    let colors;
    if ((style.fillType === 'graduated') || (style.fillType === 'continuous')) {
      const interpolator = d3ColorScale[`interpolate${style.fillPaletteNegative}`];
      let numSteps = style.fillSteps;
      if (style.fillScaleType === 'continuous') numSteps = 50;
      // eslint-disable-next-line
      colors = Array.from({ length: numSteps }, (_, i) => interpolator(i * (1 / numSteps)));
    } else if (style.fillType === 'categorical') {
      colors = {};
      let i = 0;
      let palette = d3ColorScale[`scheme${style.fillPaletteCategorised}`];
      if ((style.fillScaleInvert) && (typeof palette !== 'undefined')) {
        palette = [...palette].reverse();
      }
      source.forEachFeature((d) => {
        // eslint-disable-next-line
        const index = d['values_'][style.fillColumn];
        if (typeof palette !== 'undefined') {
          colors[index] = palette[i];
          i += 1;
          if (i === palette.length) i = 0;
        }
      });
    } else {
      colors = d3ColorScale[`scheme${style.fillPaletteNegative}`];
    }
    if ((style.fillScaleInvert) && (style.fillType !== 'categorical')) colors.reverse();
    return colors;
  }, [
    source,
    style.fillPaletteNegative,
    style.fillScaleInvert,
    style.fillType,
    style.fillSteps,
    style.fillScaleType,
    style.fillColumn,
  ]);

  useEffect(() => {
    if (!map) return undefined;

    let xOffset = 1;

    if (symbol === 'capital') xOffset = 1.9;

    let points = data;

    if (!Array.isArray(data)) {
      points = data.features;
    }

    function styleFunction(feature, selected = false) {
      let vectorStyle;
      const properties = feature.getProperties();
      let { strokeWidth } = properties.style;
      let { fillPow } = style;
      let numSteps = style.fillSteps;
      if (style.fillScaleType === 'continuous') numSteps = 50;
      if (style.fillScaleType === 'steps') fillPow = 1;

      if (selected) {
        strokeWidth += 0.5;
      }
      if (symbol === 'circle') {
        let fill = new Fill({
          color: rgba(properties.style.fill),
        });

        const colorsArrayPow = d3.scalePow()
          .exponent(fillPow)
          .domain([0, numSteps]);

        const colorScale = d3
          .scaleLinear()
          .range([0, numSteps])
          .domain([style.fillDataMin, style.fillDataMax]);

        let colorStrPow = null;
        if (style.fillType === 'categorical') {
          colorStrPow = null;
        } else {
          colorStrPow = colorsArray.map((c, i) => {
            const colorIndex = Math.ceil(colorsArrayPow(i) * numSteps);
            return (colorsArray[colorIndex]);
          });
        }

        let colorStrPowNegative = null;
        if (style.fillType === 'categorical') {
          colorStrPowNegative = null;
        } else {
          colorStrPowNegative = colorsArrayNegative.map((c, i) => {
            const colorIndex = Math.ceil(colorsArrayPow(i) * numSteps) ;
            return (colorsArrayNegative[colorIndex]);
          });
        }

        if (style.fillType === 'graduated') {
          const val = feature.get(style.fillColumn);
          let colorArrayIndex;
          let polygonColor;
          if (val > 0) {
            colorArrayIndex = Math.floor(colorScale(val)) - 1;
            polygonColor = colorStrPow[colorArrayIndex];
          } else if (val < 0) {
            colorArrayIndex = Math.floor(colorScale(Math.abs(val))) - 1;
            polygonColor = colorStrPowNegative[colorArrayIndex];
          }

          if (typeof polygonColor !== 'undefined') {
            const rgbaColor = convertRGBtoRGBA(polygonColor, 0.5);
            fill = new Fill({
              color: (rgbaColor),
            });
          } else {
            fill = new Fill({
              color: 'transparent',
            });
          }
        }
        if (style.fillType === 'categorical') {
          const polygonColor = colorsArray[feature.get(style.fillColumn)];
          if (typeof polygonColor !== 'undefined') {
            const rgbaColor = convertRGBtoRGBA(polygonColor, 0.5);
            fill = new Fill({
              color: (rgbaColor),
            });
          } else {
            fill = new Fill({
              color: 'transparent',
            });
          }
        }
        vectorStyle = new Style({
          image: new Circle({
            radius: properties.size,
            fill,
            stroke: new Stroke({
              color: rgba(properties.style.stroke),
              width: strokeWidth,
            }),
          }),
        });
      } else {
        vectorStyle = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            scale: properties.size,
            src: symbolIcons[symbol],
          }),
        });
      }
      return vectorStyle;
    }
    const features = points.map((row) => {
      let item = row;
      if (!Array.isArray(data)) {
        item = row.properties;
        [item.lon, item.lat] = row.geometry.coordinates;
      }

      let feature;

      let size = scale;

      // absolute scaling
      let exp = 0.5;
      if (scaleScaling === 'flannery') {
        exp = 0.5716;
      }
      let r = (1 / 3.14) ** exp * (10 * scale);

      if (scaleType === 'proportional') {
        const ratio = item[scaleColumn] / scaleDataMax;
        size = scale * ratio;
        r = ((item[scaleColumn] / scaleDataMax) / 3.14) ** exp * (10 * scale);
      }

      let iconStyle;
      if (symbol === 'circle') {
        feature = new Feature(new Point(fromLonLat([item.lon, item.lat])));
        feature.setProperties(item);
        // eslint-disable-next-line object-shorthand
        if (r > 0) {
          feature.setProperties({ style, size: r });
        } else {
          feature.setProperties({ style, size: r, opacity: 0 });
        }
        iconStyle = [styleFunction(feature)];
      } else {
        feature = new Feature(new Point(fromLonLat([item.lon, item.lat])));
        feature.setProperties(item);
        // eslint-disable-next-line object-shorthand
        feature.setProperties({ style: style, size: size });
        iconStyle = [styleFunction(feature)];
      }

      if (showLabels === true) {
        let label = (item[labelColumn]) ?? '';
        if (style.labelStyle.transform === 'uppercase') {
          label = String(label).toUpperCase();
        }

        const fill = new Fill({
          color: rgba(style.labelStyle.color),
        });

        let stroke = new Stroke({
          color: 'rgba(255,255,255,0.5)',
          width: 2,
        });
        if (style.labelStyle.showHalo === false) stroke = null;

        let textAlign = 'left';
        if (style.labelStyle.textAlign) textAlign = style.labelStyle.textAlign;
        let yPos = 1;
        let xPos = 7 + xOffset;
        if (scale < 0.5) {
          xPos = 4 + xOffset;
          yPos = 0;
        }

        if (scaleType === 'proportional') {
          textAlign = 'center';
          xPos = 0;
        }
        let str = String(label);
        if (labelStyle === 'population') yPos = -5;

        if (labelStyle !== 'population' || item[scaleColumn] >= 5) {
          iconStyle.push(
            new Style({
              text: new Text({
                text: str,
                font: `${style.labelStyle.fontWeight} ${style.labelStyle.fontSize}px/1.07 ${style.labelStyle.fontFamily},sans-serif`,
                textAlign,
                offsetY: yPos,
                offsetX: xPos,
                fill,
                stroke,
              }),
            }),
          );
        }

        if (labelStyle === 'population' && item[scaleColumn] >= 5) {
          str = `${item[scaleColumn]}`;
          iconStyle.push(
            new Style({
              text: new Text({
                text: numberFormatter(str),
                font: `bold ${(style.labelStyle.fontSize + 2)}px/1.07 ${style.labelStyle.fontFamily},sans-serif`,
                textAlign,
                offsetY: yPos + 10,
                offsetX: xPos,
                fill,
                stroke,
              }),
            }),
          );
        }
      }

      feature.setStyle(iconStyle);
      return feature;
    });

    const vectorLayer = new OLVectorLayer({
      source: vector({ features }),
      renderers: ['SVG', 'VML', 'Canvas'],
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);
    vectorLayer.setOpacity(opacity);

    setSymbolLayer(vectorLayer);

    // tooltip popups
    let popupOverlay;
    let selected = null;

    if ((enableTooltips) && (map) && (layerId)) {
      const tt = document.createElement('div');
      tt.classList.add('tooltip');
      tt.classList.add(`tooltip_${layerId}`);
      document.body.appendChild(tt);

      popupOverlay = new Overlay({
        element: tt,
        offset: [12, -10],
      });
      map.addOverlay(popupOverlay);
      let thisId = null;
      let id = null;

      if (tooltipListener) {
        unByKey(tooltipListener);
      }

      const pv = map.on('pointermove', (event) => {
        if (event.dragging) {
          return;
        }

        const pixel = map.getEventPixel(event.originalEvent);
        vectorLayer.getFeatures(pixel).then((vectorFeatures) => {
          const feature = vectorFeatures.length ? vectorFeatures[0] : undefined;
          let str = '';
          if (vectorFeatures.length) {
            id = feature.ol_uid;
            if ((id !== thisId) || (!thisId)) {
              if (tooltipsTitleColumn) {
                str += `${feature.get(tooltipsTitleColumn)}<br/>`;
              }
              if (tooltipsValueColumn) {
                str += `<b style="color: ${rgba(style.stroke)}"> ${numberFormatter(feature.get(tooltipsValueColumn))}</b><div style="display: inline; font-size: 9px; padding-left: 3px;">${tooltipsValueLabel}</div>`;
              }
              if (tooltipsValueColumnSecondary) {
                str += '<br />';
                str += `<div style="margin-top: -2px"><b style="color: ${rgba(style.stroke)}"> ${numberFormatter(feature.get(tooltipsValueColumnSecondary))}</b><div style="display: inline; font-size: 9px; padding-left: 3px;">${tooltipsValueLabelSecondary}</div></div>`;
              }
              if ((style.fillPaletteNegative) && (style.fillType === 'graduated')){
                str += '<br/>';
                const val = feature.get(style.fillColumn);
                if (val > 0) {
                  str += `<img height="10px" width="10px" loading="eager" src=${up} />`;
                  str += `<span style="font-weight: bold; color: darkred">${numberFormatter(Math.abs(val))}</span>`;
                } else if (val < 0) {
                  str += `<img height="10px" width="10px" loading="eager" src=${down} />`;
                  str += `<span style="font-weight: bold; color: steelblue">${numberFormatter(Math.abs(val))}</span>`;
                }
              }
              // if (tooltipsStyle === 'Population/Percentage') {
              //   str += '<br/>';
              //   str += 'pop/percent';
              // }
              tt.innerHTML = str;
              if (str.length > 0) tt.removeAttribute('hidden');
              selected = feature;
              if (symbol === 'circle') {
                if (selected) selected.setStyle(styleFunction(selected));
                feature.setStyle(styleFunction(feature, true));
              }
            }
            popupOverlay.setPosition(event.coordinate);
            thisId = id;
          } else {
            tt.setAttribute('hidden', true);
            if (symbol === 'circle') {
              if (selected) selected.setStyle(styleFunction(selected));
            }
            thisId = null;
            id = 0;
            tt.innerHTML = '&nbsp;';
          }
        });
      });

      setTooltipListener(pv);
    }

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);

        if ((typeof popupOverlay !== 'undefined')) {
          map.removeOverlay(popupOverlay);
        }

        if (enableTooltips) {
          const tooltips = document.querySelectorAll(`.tooltip_${layerId}`);
          tooltips.forEach((t) => {
            t.remove();
          });
        }
        if (tooltipListener) {
          unByKey(tooltipListener);
        }
      }
    };
  }, [
    map,
    layerId,
    primaryColor,
    source,
    JSON.stringify(data),
    symbol,
    showLabels,
    labelColumn,
    scale,
    JSON.stringify(style),
    scaleColumn,
    scaleType,
    scaleScaling,
    scaleDataMin,
    scaleDataMax,
    enableTooltips,
    tooltipsTitleColumn,
    tooltipsValueColumn,
    tooltipsValueLabel,
  ]);

  useEffect(() => {
    if (!symbolLayer) return;
    symbolLayer.setOpacity(opacity);
  }, [symbolLayer, opacity]);

  useEffect(() => {
    if (!symbolLayer) return;
    symbolLayer.setZIndex(zIndex);
  }, [symbolLayer, zIndex]);

  return null;
}

export default SymbolLayer;
