import {useState, useEffect} from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import SudanDashboard from './projects/SudanDashboard';
import SudanDashboardIframe from './projects/SudanDashboardIframe';
import styles from './App.module.css';

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      var s = document.getElementById('loader').style;
      s.opacity = 1;
      (function fade(){(s.opacity-=.1)<0?s.display="none":setTimeout(fade,100)})();
    }, 3500);
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/*"
          element={
          (
            <div className={styles.App} style={{ overflow: 'hidden' }}>
              <SudanDashboard />
            </div>
          )
        }
        />
        <Route
          path="/iframe"
          element={(
            <SudanDashboardIframe />
          )}
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
